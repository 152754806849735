/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */


import React, { useEffect, useState, useRef } from 'react';
import { Drawer } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import './EditPMModal.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Activities from './EditComponents/Activities';
import PMSchedule from './EditComponents/PMSchedule';
import Overview from './EditComponents/Overview';
import StatusTimeLog from './EditComponents/StatusTimeLog';
import Inventory from './EditComponents/inv/Inventory';
import Checklist from './EditComponents/checlistItems/Checklist';
import {
  setTab,
  deleteMaintenance,
  setAssignedTo,
  setCategory,
  setCompletedDate,
  setDescription,
  setDueFinish,
  setMaintenanceId,
  setPriority,
  setStatus,
  setTitle,
  updateMaintenance,
  setStartDate,
  getAttachment,
  setMaintenaceType,
  setSignature,
  setIsShowCost,
  closeMaintenanceModalDelete,
  openMaintenanceModalDelete,
} from '../../../redux/features/maintenance/maintenanceSlice';
import { setLocation } from '../../../redux/features/location/locationSlice';
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { getActivitiesByAuthentication } from '../../../redux/features/ticketActivity/ticketActivitySlice';
import { getLogsByAuthentication } from '../../../redux/features/ticketLog/ticketLogSlice';
import { getTicketinvsByAuthentication } from '../../../redux/features/ticketInventory/ticketInventorySlice';
import { getCompanyProfile } from '../../../redux/features/compay/companySlice';
import {
  getTickectChecklistById,
  resetChecklist,
} from '../../../redux/features/ticketChecklist/ticketChecklistSlice';
import AddWOModal from '../../workOrder/Componets/AddWOModal';
import { getWorkOrderCategoryByAuthentication } from '../../../redux/features/workOrderCategory/workOrderCategorySlice';
import {
  closeWorkOrderModalCreate,
  openWorkOrderModalCreate,
  resetWorkOrder,
  setTicketType,
  setTab as WOTab,
} from '../../../redux/features/workOrder/workOrderSlice';
import { setAsset } from '../../../redux/features/asset/assetSlice';
import { getMaintenanceCategoryByAuthentication } from '../../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import altCompanyPic from '../../../assets/img/FOX_Logo2.png.webp';
import { getChecklistByAuthentication } from '../../../redux/features/checklist/checklistSlice';
import { getInventorysByAuthentication } from '../../../redux/features/inventory/inventorySlice';
import { getMaintenanceTypeByAuthentication } from '../../../redux/features/maintenanceType/maintenanceTypeSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';

function EditPMModal({ openModalUpdate, closeModalUpdate }) {
  const [showList, setShowList] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const handelTabs = (e) => {
    dispatch(setTab(e.target.id));
  };
  const { maintenance } = useSelector((state) => state.maintenance);
  useEffect(() => {
    dispatch(
      setAssignedTo(
        maintenance?.assigned_to?.length > 0
          ? maintenance?.assigned_to?.map((i) => ({
            value: i?.id,
            label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
            image: i?.image,
          }))
          : [],
      ),
    );
    dispatch(setCategory(maintenance?.category));
    dispatch(setMaintenaceType(maintenance?.cust_type));
    dispatch(setDescription(maintenance?.description));
    dispatch(setLocation(maintenance?.location?.id));
    dispatch(setPriority(maintenance?.priority));
    dispatch(setSignature(maintenance?.signature));
    dispatch(setTitle(maintenance?.title));
    dispatch(getAttachment(maintenance?.attachment));
    dispatch(setStatus(maintenance?.status));
    dispatch(setIsShowCost(maintenance?.display_cost))
    const convertDate = (date) => {
      return moment.utc(date).format('YYYY-MM-DDTHH:mm');
    };
    dispatch(setDueFinish(convertDate(maintenance?.due_finish)));
    dispatch(setStartDate(convertDate(maintenance?.start_date)));
    dispatch(setCompletedDate(convertDate(maintenance?.completed_date)));
    dispatch(setMaintenanceId(maintenance?.id));
    dispatch(selectActions.setIdOfSite(maintenance?.location?.id));
    dispatch(selectActions.setIdOfLocation(maintenance?.sub_location?.id));
    dispatch(selectActions.setIdOfAsset(maintenance?.asset?.id));
    if (maintenance?.location?.id) {
      dispatch(selectActions.chooseSite({ id: maintenance?.location?.id, selected: maintenance?.location?.site }))
      if (maintenance?.sub_location?.id) {
          dispatch(selectActions.chooseLocation({ id: maintenance?.sub_location?.id, selected: `${maintenance?.sub_location?.room && maintenance?.sub_location?.room}${maintenance?.sub_location?.suite && `, ` + maintenance?.sub_location?.suite}${maintenance?.sub_location?.level && `, ` + maintenance?.sub_location?.level}${maintenance?.sub_location?.building && `, ` + maintenance?.sub_location?.building}` }))
      }
  }
  if (maintenance?.asset?.id) {
      dispatch(selectActions.chooseAsset({ id: maintenance?.asset?.id, selected: `${maintenance?.asset?.asset_number} - ${maintenance?.asset?.name} ${maintenance?.asset?.location && `, ` + maintenance?.asset?.location} ${maintenance?.asset.sub_location ? `${maintenance?.asset?.sub_location?.room && `, ` + maintenance?.asset?.sub_location?.room}${maintenance?.asset?.sub_location?.suite && `, ` + maintenance?.asset?.sub_location?.suite}${maintenance?.asset?.sub_location?.level && `, ` + maintenance?.asset?.sub_location?.level}${maintenance?.asset?.sub_location?.building && `, ` + maintenance?.asset?.sub_location?.building}` : ""}` }))
  }
  }, [maintenance]);

  useEffect(() => {
    dispatch(getMaintenanceTypeByAuthentication());
  }, []);
  useEffect(() => {
    const getMaintenance = () => {
      if (maintenance?.id) {
        dispatch(getChecklistByAuthentication());
        const fmValue = user?.profile?.department_name === "Facilities Management"
        dispatch(getInventorysByAuthentication({inventorySeach :"" , bool: fmValue}));
        dispatch(getActivitiesByAuthentication(maintenance?.id));
        dispatch(getLogsByAuthentication(maintenance?.id));
        dispatch(getTicketinvsByAuthentication(maintenance?.id));
        if (maintenance?.checklist?.length > 0) {
          dispatch(getTickectChecklistById(maintenance?.id));
        } else if (!maintenance?.checklist?.id) {
          dispatch(resetChecklist());
        }
      }
    };
    getMaintenance();
  }, [maintenance]);

  const {
    tab,
    title,
    description,
    priority,
    category,
    assigned_to,
    status,
    due_finish,
    startDate,
    completed_date,
    files,
    maintenanceType,
    isShowCost,
    signature,
    maintenanceModalDelete
  } = useSelector((state) => state.maintenance);
  const { idOfSite, idOfLocation, idOfAsset } = useSelector((state) => state.selectOptions);
  useEffect(() => {
    dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getMaintenanceCategoryByAuthentication());
  }, []);
  const checklists = useSelector((state) => state.checklists.checklists.results);
  const { workOrderModalCreate } = useSelector((state) => state.workOrder);
  const [showWorkOrder, setShowWorkOrder] = useState(false);

  useEffect(() => {
    if (!workOrderModalCreate) {
      setShowWorkOrder(false);
    }
  }, [workOrderModalCreate]);

  const addworkOrderHandler = () => {
    setShowWorkOrder(true);
    dispatch(resetWorkOrder());
    dispatch(setAsset(null));
    dispatch(selectActions.reset());
    dispatch(WOTab('maintenance-tab'));
    dispatch(openWorkOrderModalCreate());
    dispatch(setTicketType(idOfAsset ? 'assetCheck' : 'locationCheck'));
    if (idOfSite) {
      dispatch(selectActions.chooseSite({ id: idOfSite, selected: maintenance?.location?.site }));
      dispatch(selectActions.setIdOfSite(maintenance?.location?.id));
      if (idOfLocation) {
        dispatch(
          selectActions.chooseLocation({
            id: idOfLocation,
            selected: maintenance?.sub_location?.room,
          }),
        );
        dispatch(selectActions.setIdOfLocation(maintenance?.sub_location?.id));
      }
    } else {
      dispatch(selectActions.chooseAsset({ id: idOfAsset, selected: maintenance?.asset?.name }));
      dispatch(selectActions.setIdOfAsset(maintenance?.asset?.id));
    }
  };

  // Delete 
  const handelOpenDeletePopup = () => {
    dispatch(openMaintenanceModalDelete())
    setShowList(false);
  };

  const handleDelete = () => {
    dispatch(deleteMaintenance(maintenance?.id))
  }

  const handelSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (title) {
      formData.append('title', title);
    }
    if (description) {
      formData.append('description', description);
    }
    if (priority) {
      formData.append('priority', priority);
    }
    if (category) {
      formData.append('category', category);
    }
    if (maintenanceType) {
      formData.append('cust_type', maintenanceType);
    }
    formData.append("display_cost", isShowCost)

    if (signature && signature?.includes("base64")) {
      const handleSaveFile = (base64String) => {
        // Extract the file format from the base64 string (e.g., 'image/png')
        const format = base64String.split(';')[0].split('/')[1];

        // Remove the data:image/<format>;base64, prefix to get just the base64 data
        const data = atob(base64String.split(',')[1]);

        // Create a Uint8Array to hold the binary data
        const array = new Uint8Array(data.length);

        // Fill the Uint8Array with the binary data
        for (let i = 0; i < data.length; i += 1) {
          array[i] = data.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([array], { type: `image/${format}` });

        // Create a File object with the desired properties
        const fileName = 'image (3).png'; // Replace with your desired filename
        const lastModified = Date.now(); // Replace with the last modified timestamp if needed
        const file = new File([blob], fileName, { type: blob.type, lastModified });
        return file;
      };
      formData.append('signature', handleSaveFile(signature));
    }
    if (due_finish) {
      formData.append('due_finish', moment.utc(due_finish).format('YYYY-MM-DDTHH:mm:ss.SSZ'));
    }
    if (assigned_to?.length > 0) {
      assigned_to?.forEach((ele) => formData.append(`assigned_to`, ele?.value));
    }
    if (files?.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        if (files[i]?.file?.type?.includes('image')) {
          formData.append(`attachment[${i}][photo]`, files[i].file);
        } else {
          formData.append(`attachment[${i}][file]`, files[i].file);
        }
      }
    }
    formData.append('status', status);
    if (status === 'Completed') {
      formData.append(
        'completed_date',
        moment.utc(completed_date).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      );
    }
      formData.append('start_date', startDate !== "Invalid date" ? moment.utc(startDate).format('YYYY-MM-DDTHH:mm:ss.SSZ') : "");
    if (idOfSite) {
      formData.append('location', idOfSite);
    }
    if (idOfLocation) {
      formData.append('sub_location', idOfLocation);
    }
    if (idOfAsset) {
      formData.append('asset', idOfAsset);
    }
    if (maintenance?.schedule) {
      formData.append('schedule.start_date', maintenance?.schedule.start_date);
      formData.append('schedule.end_date', maintenance?.schedule.end_date);
      formData.append('schedule.frequency_number', maintenance?.schedule.frequency_number);
      formData.append('schedule.frequency', maintenance?.schedule.frequency);
    }
    if (title && due_finish && assigned_to.length > 0 && category) {
      if (status === "Completed" && (completed_date === null || completed_date === "Invalid date")) {
        toast.warning('Please fill the Completed Date field and submit again', {
          toastId: 'CreatedEventWarning',
        });
        return
      }
      dispatch(updateMaintenance({ id: maintenance?.id, formData }));
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };


  const { profile } = useSelector((state) => state.company);
  const [info, setInfo] = useState({
    name: '',
    address: '',
    phone_number: '',
    website: '',
    logo: '',
  });
  const dispatchProfile = useDispatch();
  useEffect(() => {
    dispatchProfile(getCompanyProfile());
  }, []);
  useEffect(() => {
    setInfo({
      name: profile?.name,
      address: profile?.address,
      phone_number: profile?.phone_number,
      website: profile?.website,
      logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info.logo,
    });
  }, [profile]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { logs } = useSelector((state) => state.logTime);
  const { ticketinvs } = useSelector((state) => state.ticketinv);
  const { ticketChecklists } = useSelector((state) => state.ticketChecklist);

  const totalLaborCost = logs?.reduce(
    (accumulator, currentValue) => accumulator + (+currentValue?.labor_cost || 0),
    0,
  );

  const totalPartCost = ticketinvs?.reduce(
    (accumulator, currentValue) => accumulator + (+currentValue?.total_cost || 0),
    0,
  );

  const total = (totalLaborCost + totalPartCost).toFixed(2);
  let deleteBtn = (
    <li role="presentation">
      <button type="button" className="font-danger" title="Delete PM" onClick={handelOpenDeletePopup}>
        {' '}
        Delete{' '}
      </button>
    </li>
  );

  if (
    user?.role === 'Technical Team' ||
    (user?.role === 'Manager' && maintenance?.service_request?.created_by?.role !== 'Manager')
  ) {
    deleteBtn = '';
  }
  const isTechCanEdit = user?.role === 'Technical Team';


  return (
    <Drawer
      anchor="right"
      open={openModalUpdate}
      onClose={closeModalUpdate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      {!showWorkOrder && (
        <>
          {' '}
          <div className="h-100 w-100">
            <div className="navbar col-lg-12 m-0 modal-header-background page-profile-header pr-0">
              <div className="col-12 mb-2 row px-2">
                <div className="my-auto">
                  <h5>#{maintenance?.ticket_number}</h5>
                </div>
                <div className="row ml-auto">
                  <div onClick={addworkOrderHandler} className="add-wo" title="Add Work Order">
                    <span>
                      <FontAwesomeIcon icon={faPlus} />{' '}
                    </span>
                    create work order
                  </div>
                  <div className="dropdown" onClick={() => setShowList(!showList)}>
                    {showList && (
                      <div className="tck-overlay" onClick={() => setShowList(!showList)}></div>
                    )}
                    <button
                      type="button"
                      data-toggle="dropdown"
                      id="dropdownMenu1"
                      className="btn tertiary-btn ml-2 "
                      title="View More"
                    >
                      <div className="fas fa-ellipsis-h"></div>
                    </button>
                    <ul
                      className={`dropdown-menu ${showList && 'dropdown-menu-active'}`}
                      role="menu"
                      aria-labelledby="dropdownMenu1"
                    >
                      {deleteBtn}
                      <li role="presentation">
                        <button
                          type="button"
                          className="font-danger"
                          title="Print PM"
                          onClick={handlePrint}
                        >
                          {' '}
                          Print{' '}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <button
                      onClick={() => dispatch(closeModalUpdate())}
                      type="button"
                      className="btn secondary-btn ml-2"
                      data-dismiss="modal"
                    >
                      <div className="fas fa-times"></div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row justify-content-start mt-auto col-lg-12 col-sm-12">
                <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist">
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'service-tab' && 'active'} cursor-pointer`}
                      id="service-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      Overview
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'status-time-tab' && 'active'} cursor-pointer`}
                      id="status-time-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      Status & Time Log
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'checklist-tab' && 'active'} cursor-pointer`}
                      id="checklist-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      Checklist
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'inventory-tab' && 'active'} cursor-pointer`}
                      id="inventory-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      Inventory
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'activity-tab' && 'active'} cursor-pointer`}
                      id="activity-tab"
                      data-toggle="tab"
                      role="tab"
                    >
                      Activities
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={handelTabs}
                      className={`nav-link ${tab === 'schedule-tab-edit' && 'active'
                        } cursor-pointer`}
                      id="schedule-tab-edit"
                      data-toggle="tab"
                      role="tab"
                    >
                      Maintenance Schedule
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content col-lg-12 col-sm-12 pb-5" id="custom-tabs-two-tabContent">
              {tab === 'service-tab' && <Overview isTechCanEdit={isTechCanEdit} />}
              {tab === 'status-time-tab' && <StatusTimeLog id={maintenance?.id} />}
              {tab === 'checklist-tab' && <Checklist id={maintenance?.id} />}
              {tab === 'inventory-tab' && <Inventory tktId={maintenance?.id} />}
              {tab === 'activity-tab' && <Activities id={maintenance?.id} />}
              {tab === 'schedule-tab-edit' && <PMSchedule />}
            </div>

            <div className=" row m-0 p-2 page-profile-footer">
              <button className="btn btn-primary" type="submit" onClick={handelSubmit}>
                <span className="button__text">Save</span>
              </button>
              <div className="pl-2 my-auto gcolor">
                <span>
                  Last Save {moment(maintenance.modified_at).format('MMMM Do YYYY, h:mm a')}
                </span>
              </div>
            </div>
          </div>
          {/* <div
            className="card-body"
            style={{ paddingBottom: '1.25rem', display: 'none' }}
            ref={componentRef}
          >
            <div className="assetPdf">
              <div className="pdfheader">
                <img
                  src={info.logo ? info.logo : altCompanyPic}
                  alt=""
                  className="brand-image img-company"
                  id="result_company_base"
                  height="100"
                  width="100"
                  style={{ marginRight: '20px' }}
                />
                <div>
                  {' '}
                  <strong>{info.name}</strong>
                  <br />
                  <span>{info.address}</span> <br />
                  <span>Tel {info.phone_number}</span>{' '}
                  <span style={{ marginLeft: '10px' }}> Website {info.website}</span>
                </div>
              </div>
              <div className="firstHalf">
                <div className="right">
                  <div>
                    <strong>Requster Particulars</strong>
                  </div>
                  <div>{maintenance?.service_request?.name}</div>
                  <div>{`Phone : ${maintenance?.service_request?.phone || 'Unknown'}`}</div>
                  <div>{`Email: ${maintenance?.service_request?.email}`}</div>
                  <div>{`Issued on : ${moment(maintenance?.created_at).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )}`}</div>
                  <div>{`Service Date: ${moment(due_finish).format('YYYY-MM-DD-hh:mm')} `}</div>
                  <div>{`Last Modified : ${moment(maintenance?.modified_at).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )}`}</div>
                </div>
                <div className="left">
                  <div>
                    {' '}
                    <strong>{`Maintenance : ${maintenance?.ticket_number}`}</strong>
                  </div>
                  {maintenance?.status && <div>{`Satus : ${maintenance?.status}`}</div>}
                  {maintenance?.priority && <div>{`Priority: ${maintenance?.priority}`}</div>}
                  {maintenance?.category && <div>{`Category: ${maintenance?.category}`}</div>}
                </div>
              </div>

              {maintenance.asset && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>Asset</div>
                  {maintenance?.asset?.name && (
                    <div className="assetrow">Name: {maintenance?.asset?.name}</div>
                  )}
                  {maintenance?.asset?.category && (
                    <div className="assetrow">Category: {maintenance?.asset?.category}</div>
                  )}
                  {maintenance?.asset?.serial_number && (
                    <div className="assetrow">
                      Serial Number: {maintenance?.asset?.serial_number}
                    </div>
                  )}
                  {maintenance?.asset?.asset_type && (
                    <div className="assetrow">Asset Type: {maintenance?.asset?.asset_type}</div>
                  )}
                  {maintenance?.asset?.location && (
                    <div className="assetrow">Location : {maintenance?.asset?.location}</div>
                  )}
                </>
              )}

              {maintenance?.location?.site && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>Location</div>
                  {maintenance?.location?.site && (
                    <div className="assetrow">Location: {maintenance.location.site}</div>
                  )}
                  {maintenance?.sub_location?.room && (
                    <div className="assetrow">Sublocation: {maintenance.sub_location.room}</div>
                  )}

                  {maintenance?.sub_location?.bulding && (
                    <div className="assetrow">Bulding: {maintenance.sub_location.bulding}</div>
                  )}
                  {maintenance?.sub_location?.level && (
                    <div className="assetrow">Level: {maintenance.sub_location.level}</div>
                  )}
                </>
              )}
              {maintenance?.attachment && maintenance?.attachment.length > 0 && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    Attachment
                  </div>
                  <div style={{ display: 'flex' }}>
                    {maintenance.attachment.map((item) => (
                      <img
                        src={item.photo}
                        alt=""
                        className="brand-image img-company"
                        id="result_company_base"
                        height="100"
                        width="100"
                        style={{ marginRight: '20px' }}
                      />
                    ))}
                  </div>
                </>
              )}

              {isShowCost && ticketinvs?.length > 0 && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    Parts Used
                  </div>
                  <table id="service-history-list" className="table table-togglable w-100">
                    <thead>
                      <tr>
                        <th>
                          <center>#</center>
                        </th>
                        <th>
                          <center>Name</center>
                        </th>
                        <th>
                          <center>Quantity Used </center>
                        </th>
                        <th>
                          <center>Cost</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketinvs?.length > 0 &&
                        ticketinvs?.map((inv) => (
                          <tr key={inv?.id}>
                            <td>
                              <center>
                                <b>{inv.id}</b>{' '}
                              </center>
                            </td>
                            <td>
                              <center>
                                <span className="d-none"></span>
                                {inv?.name}
                              </center>
                            </td>

                            <td>
                              <center>{inv?.total_used}</center>
                            </td>

                            <td>
                              <center>{inv?.cost}</center>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td></td>
                        <td></td>

                        <td>
                          <center>
                            {' '}
                            <strong>TOTAL PARTS COST</strong>{' '}
                          </center>
                        </td>
                        <td>
                          <center>
                            <strong>${totalPartCost}</strong>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              {ticketChecklists?.items?.length > 0 && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    CHECKLIST: {ticketChecklists?.title}
                  </div>
                  <table id="service-history-list" className="table table-togglable w-100">
                    <thead>
                      <tr>
                        <th>
                          <center>Name</center>
                        </th>
                        <th>
                          <center>Value</center>
                        </th>
                        <th>
                          <center>Comment</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketChecklists?.items?.length > 0 &&
                        ticketChecklists?.items?.map((check) =>
                          check.type === 'Text' ? (
                            <tr key={check?.id}>
                              <td>
                                <center>
                                  <b>{check.name}</b>{' '}
                                </center>
                              </td>
                              <td>
                                <center>
                                  <b>{check?.result?.is_checked ? 'True' : 'False'}</b>{' '}
                                </center>
                              </td>
                              <td>
                                <center>
                                  <span className="d-none"></span>
                                  {check?.result?.comment}
                                </center>
                              </td>
                            </tr>
                          ) : (
                            <tr key={check?.id}>
                              <td>
                                <center>
                                  <b>{check.name}</b>{' '}
                                </center>
                              </td>
                              <td>
                                <center>
                                  <b>{check?.result?.actual}</b>{' '}
                                </center>
                              </td>
                              <td>
                                <center>
                                  <span className="d-none"></span>
                                  {check?.result?.comment}
                                </center>
                              </td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </>
              )}

              {isShowCost && logs?.length > 0 && (
                <>
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    LABOR COST
                  </div>
                  <table id="service-history-list" className="table table-togglable w-100">
                    <thead>
                      <tr>
                        <th>
                          <center>Time Taken</center>
                        </th>
                        <th>
                          <center>Hourly Rate </center>
                        </th>
                        <th>
                          <center>Name </center>
                        </th>
                        <th>
                          <center>Date</center>
                        </th>
                        <th>
                          <center>Labor Cost</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs?.length > 0 &&
                        logs?.map((log) => (
                          <tr key={log?.id}>
                            <td>
                              <center>
                                <b>
                                  {log?.hours} Hours {log?.minutes} Minutes{' '}
                                </b>{' '}
                              </center>
                            </td>
                            <td>
                              <center>
                                <span className="d-none"></span>${log?.hourly_rate}
                              </center>
                            </td>

                            <td>
                              <center>
                                {log?.created_by?.first_name} {log?.created_by?.last_name} (
                                {log?.created_by?.role === 'CliAdm'
                                  ? 'Admin'
                                  : log?.created_by?.role}
                                )
                              </center>
                            </td>

                            <td>
                              <center>{moment(log?.work_date).format('YYYY-MM-DD')}</center>
                            </td>
                            <td>
                              <center>${log?.labor_cost}</center>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td></td>
                        <td></td>

                        <td></td>

                        <td>
                          <center>
                            {' '}
                            <strong>TOTAL LABOR COST</strong>{' '}
                          </center>
                        </td>
                        <td>
                          <center>
                            <strong>${totalLaborCost}</strong>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <br />
              <br />
              {isShowCost &&
                <>
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>Total Cost</div>
                  <div className="totalcostbody">
                    <div className="right">
                      <div>
                        <strong>Details</strong>
                      </div>
                      <strong>
                        <div>Labor Cost</div>
                      </strong>
                      <strong>
                        <div>Part Cost</div>
                      </strong>
                    </div>
                    <div className="left">
                      <div>
                        <strong>Cost</strong>
                      </div>
                      <strong>
                        <div>${totalLaborCost}</div>
                      </strong>
                      <strong>
                        <div>${totalPartCost}</div>
                      </strong>
                    </div>
                  </div>
                  <div className="total">
                    <div className="right">
                      <strong>
                        <div>TOTAL COST </div>
                      </strong>
                    </div>
                    <div className="left" style={{ paddingRight: '0.5rem' }}>
                      <strong>
                        <div>${total}</div>
                      </strong>
                    </div>
                  </div>
                </>
              }
              <br />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div style={{ width: '200px' }}>
                  {maintenance?.status === "Completed" && maintenance?.signature &&
                    <div>
                      <img className="sign-image" src={maintenance?.signature}
                        style={{ height: '60px', width: "200px", marginLeft: "5px", marginBottom: "5px" }} alt="Signature" />
                    </div>
                  }
                  <div style={{ borderTop: '1px solid black' }}>Signature</div>
                </div>
                <div style={{ width: '200px' }}>
                  {maintenance?.status === "Completed" &&
                    <div>
                      {moment(maintenance?.completed_date).format('YYYY-MM-DD')}
                    </div>
                  }
                  <div style={{ borderTop: '1px solid black' }}>Date</div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="card-body"
            style={{ paddingBottom: '1.25rem', display: "none" }}
            ref={componentRef}>
            <div className="assetPdf">
              <div className='pdfheader'>
                <img
                  src={info.logo ? info.logo : altCompanyPic}
                  alt='Company logo'
                  className='brand-image img-company'
                  id='result_company_base'
                  height='100'
                  width='100'
                  style={{ marginRight: '20px' }}
                />
                <div> <strong>{info.name}</strong>
                  <br />
                  <span>{info.address}</span> <br />
                  <span>Tel: {info.phone_number ? info.phone_number : "—"}</span> <span style={{ marginLeft: '10px' }}> Website: {info.website ? info.website : "—"}</span>
                </div>
              </div>
              <div className="firstHalf">
                <div className="right">
                  <div><strong>Requester Particulars</strong></div>
                  <div>{maintenance?.service_request?.name}</div>
                  <div>{`Phone : ${maintenance?.service_request?.phone || "Unknown"}`}</div>
                  <div>{`Email: ${maintenance?.service_request?.email}`}</div>
                  <div>{`Issued on : ${moment(maintenance?.created_at).format('MMMM Do YYYY, h:mm:ss a')}`}</div>
                  <div>{`Service Date: ${moment(due_finish).format("YYYY-MM-DD-hh:mm")} `}</div>
                  <div>{`Last Updated : ${moment(maintenance?.modified_at).format('MMMM Do YYYY, h:mm:ss a')}`}</div>
                </div>
                <div className="left">
                  <div> <strong>{`Preventive Maintenance #: ${maintenance?.ticket_number}`}</strong></div>
                  {maintenance?.status && (<div>{`Status : ${maintenance?.status}`}</div>)}
                  {/* {maintenance?.status === "Completed" && (<div>{`Completed Date: ${moment(maintenance?.completed_date).format("YYYY-MM-DD-hh:mm")}`}</div>)} */}
                  {maintenance?.priority && (<div>{`Priority: ${maintenance?.priority}`}</div>)}
                  {maintenance?.category && (<div>{`Category: ${maintenance?.category}`}</div>)}
                </div>
              </div>
              <br />
              <br />
              <div className='main-border'>
                <div className='header-of-feature'><h6>Preventive Maintenance</h6></div>
                <div className='body-of-feature'>
                  <div><h6 style={{ fontSize: '20px', fontWeight: "bold", display: "inline" }}>Title: </h6><span style={{ fontSize: '20px' }}>{maintenance?.title}</span> </div>
                  {maintenance?.description && (<div><h6 style={{ fontSize: '20px', fontWeight: "bold", display: "inline" }}>Description: </h6><span style={{ fontSize: '20px' }}>{maintenance?.description}</span></div>)}
                </div>
              </div>

              {(maintenance?.asset || maintenance?.location?.site) && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>DETAILS</h6></div>
                    <div className='body-of-feature'>
                      {maintenance?.asset &&
                        <div className='mb-2'>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Asset:</div>
                          {maintenance?.asset?.name && (
                            <div>Name: {maintenance?.asset.name}</div>
                          )}
                          {maintenance?.asset?.category && (
                            <div>Category: {maintenance?.asset.category}</div>
                          )}
                          {maintenance?.asset?.asset_number && (
                            <div>Serial Number: {maintenance?.asset.asset_number}</div>
                          )}
                          {maintenance?.asset?.asset_type && (
                            <div>Type: {maintenance?.asset.asset_type}</div>
                          )}
                          {!maintenance?.location?.site && maintenance?.asset?.location && (
                            <div>Location: {maintenance?.asset.location}</div>
                          )}
                        </div>}
                      {maintenance?.location?.site &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Location:</div>
                          {maintenance?.location?.site && (
                            <div>Location: {maintenance.location.site}</div>
                          )}
                          {maintenance?.sub_location?.room && (
                            <div>Sublocation: {maintenance.sub_location.room}</div>
                          )}
                        </>}
                    </div>
                  </div>
                </>
              )}

              {maintenance?.attachment && maintenance?.attachment.length > 0 && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>Attachment</h6></div>
                    <div className='body-of-feature'>
                      <div style={{ display: 'flex' }}>
                        {maintenance.attachment.map((item) => (
                          <img
                            src={item.photo}
                            alt=''
                            className='brand-image img-company'
                            id='result_company_base'
                            height='100'
                            width='100'
                            style={{ marginRight: '20px' }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )
              }

              {ticketChecklists?.items?.length > 0 && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>CHECKLIST: {ticketChecklists?.title}</h6></div>
                    <div className='body-of-feature'>
                      <table
                        id="service-history-list"
                        className="table table-togglable w-100"

                      >
                        <thead>
                          <tr>
                            <th><center>Task</center></th>
                            <th><center>Value</center></th>
                            <th><center>Comment</center></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketChecklists?.items?.length > 0 && ticketChecklists?.items?.map(check => (
                            check.type === "Text" ? <tr key={check?.id}>
                              <td><center><b>{check.name}</b> </center></td>
                              <td><center>  <b>{check?.result?.is_checked ? "True" : "False"}</b>  </center></td>
                              <td>
                                <center><span className="d-none"></span>
                                  {check?.result?.comment}</center>
                              </td>
                            </tr> : <tr key={check?.id}>
                              <td><center><b>{check.name}</b> </center></td>
                              <td><center><b>{check?.result?.actual}</b> </center></td>
                              <td>
                                <center><span className="d-none"></span>
                                  {check?.result?.comment}</center>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )
              }


              {isShowCost && (ticketinvs?.length > 0 || logs?.length > 0) && (
                <>
                  <br />
                  <br />
                  <div className='main-border'>
                    <div className='header-of-feature'><h6>Cost</h6></div>
                    <div className='body-of-feature'>
                      {logs?.length > 0 &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Labor Cost:</div>
                          <table
                            id="service-history-list"
                            className="table table-togglable w-100"
                          >
                            <thead>
                              <tr>
                                <th><center>Time Taken</center></th>
                                <th><center>Hourly Rate </center></th>
                                <th><center>Name </center></th>
                                <th><center>Date</center></th>
                                <th><center>Labor Cost</center></th>
                              </tr>
                            </thead>
                            <tbody>
                              {logs?.length > 0 && logs?.map(log => (
                                <tr key={log?.id}>
                                  <td><center><b>{log?.hours} Hours {log?.minutes} Minutes </b> </center></td>
                                  <td>
                                    <center><span className="d-none"></span>
                                      ${log?.hourly_rate}</center>
                                  </td>

                                  <td>
                                    <center>
                                      {log?.created_by?.first_name} {log?.created_by?.last_name} ({log?.created_by?.role === "CliAdm" ? "Admin" : log?.created_by?.role})
                                    </center>
                                  </td>

                                  <td><center>{moment(log?.work_date).format("YYYY-MM-DD")}</center></td>
                                  <td><center>${log?.labor_cost}</center></td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td><center> <strong>TOTAL LABOR COST</strong> </center></td>
                                <td><center><strong>${totalLaborCost}</strong></center></td>
                              </tr>

                            </tbody>
                          </table>
                        </>}
                      {ticketinvs?.length > 0 && <>
                        <div style={{ fontSize: '20px', fontWeight: "bold" }}>Parts Used:</div>
                        <table
                          id="service-history-list"
                          className="table table-togglable w-100"

                        >
                          <thead>
                            <tr>
                              <th><center>#</center></th>
                              <th><center>Name</center></th>
                              <th><center>Quantity Used </center></th>
                              <th><center>Cost</center></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ticketinvs?.length > 0 && ticketinvs?.map(inv => (
                              <tr key={inv?.id}>
                                <td><center><b>{inv.id}</b> </center></td>
                                <td>
                                  <center><span className="d-none"></span>
                                    {inv?.name}</center>
                                </td>

                                <td>
                                  <center>
                                    {inv?.total_used}
                                  </center>
                                </td>

                                <td><center>${inv?.cost}</center></td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td>
                              </td>
                              <td><center> <strong>TOTAL PARTS COST</strong> </center></td>
                              <td><center><strong>${totalPartCost}</strong></center></td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                      }
                      <br />
                      <br />
                      {isShowCost &&
                        <>
                          <div style={{ fontSize: '20px', fontWeight: "bold" }}>Total Cost:</div>
                          <div className="totalcostbody">
                            <div className="right">
                              <div><strong>Details</strong></div>
                              <strong><div>Labor Cost</div></strong>
                              <strong><div>Part Cost</div></strong>
                            </div>
                            <div className="left">
                              <div><strong>Cost</strong></div>
                              <strong><div>${totalLaborCost}</div></strong>
                              <strong><div>${totalPartCost}</div></strong>
                            </div>
                          </div>
                          <div className="total">
                            <div className="right">
                              <strong><div>TOTAL COST </div></strong>
                            </div>
                            <div className="left" style={{ paddingRight: "0.5rem" }}>
                              <strong><div>${total}</div></strong>
                            </div>
                          </div>
                        </>
                      }

                    </div>
                  </div>
                </>
              )
              }
              <br />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", breakInside: 'avoid' }}>
                <div style={{ width: '200px' }}>
                  {maintenance?.status === "Completed" && maintenance?.signature &&
                    <div>
                      <img className="sign-image" src={maintenance?.signature}
                        style={{ height: '60px', width: "200px", marginLeft: "5px", marginBottom: "5px" }} alt="Signature" />
                    </div>
                  }
                  <div style={{ borderTop: '1px solid black' }}>Signature</div>
                </div>
                <div style={{ width: '200px' }}>
                  {maintenance?.status === "Completed" &&
                    <div>
                      {moment(maintenance?.completed_date).format('YYYY-MM-DD')}
                    </div>
                  }
                  <div style={{ borderTop: '1px solid black' }}>Date</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showWorkOrder && (
        <AddWOModal
          openModalCreate={workOrderModalCreate}
          closeModalCreate={() => dispatch(closeWorkOrderModalCreate())}
          checklistData={checklists}
          createdFrom={maintenance?.id}
        />
      )}
      {maintenanceModalDelete && (
        <PopUpDeleteModal
          showModal={maintenanceModalDelete}
          handleClose={() => dispatch(closeMaintenanceModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete maintenance"
          modalBodyText="Are you sure you want to delete this maintenance schedule?"
        />
      )}
    </Drawer>
  );
}

export default EditPMModal;
