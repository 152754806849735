/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTicketinv, updateTicketinv } from '../../../../../redux/features/ticketInventory/ticketInventorySlice';
import { getInventorysByAuthentication } from '../../../../../redux/features/inventory/inventorySlice';

function InvTable({setMyInv , myInv ,inv , tktId , invId, handelInvId }) {

const {isTicketinvUpdateLoading} =   useSelector(state => state.ticketinv)
const [used, setused] = useState()
const [total, setTotal] = useState()
const {user} = useSelector(state => state.user)
const fmValue = user?.profile?.department_name === "Facilities Management"

useEffect(() => {
    setTotal(inv.total + inv.total_used)
    setused(inv.total_used)
}, [])
const dispatch = useDispatch()
const data = {
    "used": used,
    "inventory": [inv.id]
}
    const handelMinus = async ()=>{
        if (total < +used) {
            setused(total - 1)
            await dispatch(updateTicketinv(  {tktId,   id : inv.id ,data: {...data , used : total - 1}}))
            await dispatch(getInventorysByAuthentication({ inventorySeach:'', bool: fmValue }));
        }

       else if (used > 0) {
            setused(+used - 1)
            await dispatch(updateTicketinv({tktId, id : inv.id , data:{...data , used : +used - 1}}))
            await dispatch(getInventorysByAuthentication({ inventorySeach:'', bool: fmValue })); 
        }
    }
    const handelUsed = (e)=>{
        setused(+e.target.value)
    }
    const handelPlus = async ()=>{
        if (total > used) {
            setused(+used + 1) 
            await dispatch(updateTicketinv({ tktId, id : inv.id , data: {...data , used : +used + 1}}))
            await dispatch(getInventorysByAuthentication({ inventorySeach:'', bool: fmValue })); 
        }
       
    }

    const handelDelete = async ()=>{
       await dispatch(deleteTicketinv({tktId , id : inv.id}))
       await dispatch(getInventorysByAuthentication({ inventorySeach:'', bool: fmValue })); 
        setMyInv(myInv.filter(i=> i !== inv.id ))
    }

  return (
    <tr onClick={()=>handelInvId(inv?.id)} style={{textAlign: "left"}}>
    <td  className="pl-0" style={{textAlign: "center", verticalAlign: "middle"}}>{inv.inventory_id}</td>
    <td style={{verticalAlign: "middle"}}>{inv.name}</td>
    <td  className="pl-0" 
    style={{textAlign: "center", verticalAlign: "middle"}}>{inv.total}</td>
    <td>
        <div className="col-12 row m-0 p-0">
            <div className="center" >
                    <div className="input-group flex-nowrap">
                        <span className="input-group-btn">
                            <button disabled={(+used === 0) || isTicketinvUpdateLoading} type="button" className="btn tertiary-btn" id="btn-number-minus-part.spare_parts.id"  data-type="minus" data-field="quant[1]" 
                            onClick={handelMinus}>
                            <div className="fas fa-minus" aria-hidden="true"></div>
                            </button>
                        </span>
                        <div>
                        {isTicketinvUpdateLoading && inv?.id === invId && <p className='used-status'>Loading...</p>}
                        <input type="text" 
                        className="form-control search-bar  
                        mx-1 input-number"
                        size="4"
                        value={used}
                        onChange={(e)=> handelUsed(e)}
                        style={{width: "50px"}} 
                        />
                        </div>
                        <span className="input-group-btn">
                            <button disabled={(+used === total) || isTicketinvUpdateLoading} type="button" className="btn tertiary-btn"  
                            data-type="plus" data-field="quant[1]" onClick={handelPlus}>
                            <div className="fas fa-plus" aria-hidden="true"></div>
                            </button>
                        </span>
                    </div>
            </div>
        </div>
    </td>
    <td id="total-cost-part.spare_parts.id"  className="pl-0" style={{textAlign: "center", verticalAlign: "middle"}}>
        {inv?.total_cost?.toFixed(2)}
    </td>
    <td style={{ verticalAlign: "middle"}}>
        <span  className="btn tertiary-btn" 
        onClick={handelDelete}>
            <i className="fa fa-trash"></i>
        </span>
    </td>
    </tr>
  )
}

export default InvTable