import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import eventService from './dashboardService';
import meterService from '../meter/meterService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  events: [],
  event: {},



  // modals
  eventModalDelete: false,
  eventModalUpdate: false,
  eventModalCreate: false,

  // Event fields
  eventId: null,
  ticketType: "",
  title: "",
  description: "",
  priority: "",
  category: "",
  due_finish: "",
  start_date: "",
  completed_date: "",
  assigned_to: [],
  asset: "",
  location: "",
  time : "",
  status : "",
  fixedDate : "",
  rquestor: "",

  // success states
  isEventGetSuccess: false,
  isEventCreateSuccess: false,
  isEventDeleteSuccess: false,
  isEventUpdateSuccess: false,


  // loading states
  isEventCreateLoading: false,
  isEventsGetLoading: false,
  isEventGetLoading: false,
  isEventDeleteLoading: false,
  isEventUpdateLoading: false,


  // error states
  isEventGetError: false,
  isEventCreateError: false,
  isEventDeleteError: false,
  isEventUpdateError: false,
};



export const createEvent = createAsyncThunk('createEvent', async (data, thunkAPI) => {
  const createEventRes = await smartTryCatch(eventService.createEvent, data, thunkAPI);
  return createEventRes;
});

export const getEventsByAuthentication = createAsyncThunk(
  'getEventsByAuthentication',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getEventsByAuthentication,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);
export const getMeterEventsByAuthentication = createAsyncThunk(
  'getMeterEventsByAuthentication',
  async (data, thunkAPI) => {
    const getEvents = await smartTryCatch(
      eventService.getMeterEventsByAuthentication,
      data,
      thunkAPI,
    );
    return getEvents;
  },
);

export const getEventById = createAsyncThunk('getEventById', async (data, thunkAPI) => {
  const getEvent = await smartTryCatch(eventService.getEventById, data, thunkAPI);
  return getEvent;
});
export const getMeterEventById = createAsyncThunk('getMeterEventById', async (data, thunkAPI) => {
  const getEvent = await smartTryCatch(meterService.getMeterById, data, thunkAPI);
  return getEvent;
});



export const deleteEvent = createAsyncThunk('deleteEvent', async (data, thunkAPI) => {
  const deleteEventRes = await smartTryCatch(eventService.deleteEvent, data, thunkAPI);
  return deleteEventRes;
});

export const updateEvent = createAsyncThunk('updateEvent', async (data, thunkAPI) => {
  const updateEventRes = await smartTryCatch(eventService.updateEvent, data, thunkAPI);
  return updateEventRes;
});

export const patchMeterEvent = createAsyncThunk('patchMeterEvent', async (data, thunkAPI) => {
  const patchMeterEventRes = await smartTryCatch(meterService.patchMeter, data, thunkAPI);
  return patchMeterEventRes;
});


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isEventCreateError = false;
      state.isEventCreateSuccess = false;
      state.isEventCreateLoading = false;
      state.isEventGetError = false;
      state.isEventGetSuccess = false;
      state.isEventGetLoading = false;
      state.isEventDeleteError = false;
      state.isEventDeleteSuccess = false;
      state.isEventDeleteLoading = false;
      state.isEventUpdateError = false;
      state.isEventUpdateSuccess = false;
      state.isEventUpdateLoading = false;
      state.isEventsGetLoading = false;
      // state.event = null;
    },
    openEventModalCreate: (state) => {
      state.eventModalCreate = true;
    },
    closeEventModalCreate: (state) => {
      state.eventModalCreate = false;
    },
    openEventModalUpdate: (state) => {
      state.eventModalUpdate = true;
    },
    closeEventModalUpdate: (state) => {
      state.eventModalUpdate = false;
    },
    openEventModalDelete: (state) => {
      state.eventModalDelete = true;
    },
    closeEventModalDelete: (state) => {
      state.eventModalDelete = false;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },

    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setDueFinish: (state, action) => {
      state.due_finish = action.payload;
    },
    setAssignedTo: (state, action) => {
      state.assigned_to = action.payload;
    },
    setLocation: (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset: (state, action) => {
      state.asset = Number(action.payload);
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setStartedDate: (state, action) => {
      state.start_date = action.payload;
    },
    setCompletedDate: (state, action) => {
      state.completed_date = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setFixedDate: (state, action) => {
      state.fixedDate = action.payload;
    },
    setRequestor: (state, action) => {
      state.rquestor = action.payload;
    },

    resetEvent: (state) => {
      state.eventId = null
      state.ticketType = ""
      state.title = ""
      state.description = ""
      state.priority = ""
      state.category = ""
      state.due_finish = ""
      state.assigned_to = []
      state.asset = ""
      state.location = ""
      state.time = ""
      state.status = ""
      state.start_date = ""
      state.completed_date = ""
      state.fixedDate = ""
      state.rquestor= ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.isEventCreateLoading = true;
        state.isEventCreateSuccess = false;
        state.isEventCreateError = false;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isEventCreateLoading = false;
        state.isEventCreateSuccess = true;
        state.isEventCreateError = false;
        state.events.push(action.payload);
      })
      .addCase(createEvent.rejected, (state) => {
        state.isEventCreateLoading = false;
        state.isEventCreateSuccess = false;
        state.isEventCreateError = true;
      })
      .addCase(getEventsByAuthentication.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getEventsByAuthentication.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.events = action.payload;
      })
      .addCase(getEventsByAuthentication.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMeterEventsByAuthentication.pending, (state) => {
        state.isEventsGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMeterEventsByAuthentication.fulfilled, (state, action) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.events = [...state.events, ...action.payload];
      })
      .addCase(getMeterEventsByAuthentication.rejected, (state) => {
        state.isEventsGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getEventById.pending, (state) => {
        state.isEventGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getEventById.fulfilled, (state, action) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.event = action.payload;
      })
      .addCase(getEventById.rejected, (state) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(getMeterEventById.pending, (state) => {
        state.isEventGetLoading = true;
        state.isEventGetSuccess = false;
        state.isEventGetError = false;
      })
      .addCase(getMeterEventById.fulfilled, (state, action) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = true;
        state.isEventGetError = false;
        state.event = action.payload;
      })
      .addCase(getMeterEventById.rejected, (state) => {
        state.isEventGetLoading = false;
        state.isEventGetSuccess = false;
        state.isEventGetError = true;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isEventDeleteLoading = true;
        state.isEventDeleteSuccess = false;
        state.isEventDeleteError = false;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isEventDeleteLoading = false;
        state.isEventDeleteSuccess = true;
        state.isEventDeleteError = false;
        state.events = state.events.filter((event) => event.id !== action.payload);
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.isEventDeleteLoading = false;
        state.isEventDeleteSuccess = false;
        state.isEventDeleteError = true;
      })
      .addCase(updateEvent.pending, (state) => {
        state.isEventUpdateLoading = true;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = false;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = true;
        state.isEventUpdateError = false;
        state.events = state.events?.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
      })
      .addCase(updateEvent.rejected, (state) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = true;
      })
      .addCase(patchMeterEvent.pending, (state) => {
        state.isEventUpdateLoading = true;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = false;
      })
      .addCase(patchMeterEvent.fulfilled, (state, action) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = true;
        state.isEventUpdateError = false;
        state.events = state.events?.map((event) =>
          event.id === action.payload.id && event?.ticket_type === "meter" ? {...action.payload, ticket_type:"meter"} : event,
        );
      })
      .addCase(patchMeterEvent.rejected, (state) => {
        state.isEventUpdateLoading = false;
        state.isEventUpdateSuccess = false;
        state.isEventUpdateError = true;
      })

  },
});

export const {
reset,
openEventModalCreate,
closeEventModalCreate,
setEventId,
setTicketType,
setTitle,
setDescription,
setPriority,
setCategory,
setDueFinish,
setAssignedTo,
setLocation,
setAsset,
setTime,
setStatus,
setEvent,
setStartedDate,
setCompletedDate,
openEventModalUpdate,
closeEventModalUpdate,
isEventGetLoading,
resetEvent,
setEvents,
setFixedDate,
setRequestor
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
