/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import classes from '../assetsPage/assestsPage.module.css';
import {
  closeSiteModeul,
  getLocationByPagination,
  setLocationsReset,
  closeAddFloor,
} from '../../redux/features/location/locationSlice';
import PropertyManagementHeader from '../../components/PropertyManagementComponent/PropertyManagementHeader';
import PropertyManagementTable from '../../components/PropertyManagementComponent/PropertyManagementTable/PropertyManagementTable';
import Pagination from '../../components/pagination/pagination';
import {
  reset,
  setCloseProp,
} from '../../redux/features/PropertyManagement/PropertyManagementSlice';
import PropertyManagementForm from '../../components/PropertyManagementComponent/PropertyManagementForm/PropertyManagementForm';

const PropertyManagement = () => {
  const { user } = useSelector(state => state.user);
  const [showSearchBar, setSearchBar] = useState(true);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [locationsData, setLocations] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const { showProp, isPropUpdateSuccess, isPropUpdateError } = useSelector(state => state.property);
  const { showSiteModule, showFloorAdd, locationsCount } = useSelector(state => state.location);
  const { locations, location } = useSelector(state => state.location);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setShow(false);
    setShowAdd(false);
    setSearchBar(true);
    dispatch(setCloseProp()); // property
    dispatch(closeSiteModeul());
    dispatch(closeAddFloor());
  }, []);

  useEffect(() => {
    setLocationsReset([]);
    const getDataRows = async () => {
      await dispatch(getLocationByPagination({ rowPerPage, currentPage, searchValue }));
    };
    getDataRows();
  }, [rowPerPage, currentPage, searchValue]);

  useEffect(() => {
    setLocations(locations);
  }, [locations, showSiteModule]);

  // select the value of each location that are needed
  const modifiedData = locationsData.map(loc => {
    return {
      id: loc?.id || '-',
      site: loc?.site || '-',
      owner: loc?.management?.owner || '-',
      category: loc?.management?.category || '-',
      // duration: `${loc?.management?.start_rent_date?.split('T')[0] || '-'} ${
      //   loc?.management?.start_rent_date ? 'to' : ''
      // } ${loc?.management?.end_rent_date?.split('T')[0] || ''}`,
    };
  });
  // { showSearchBar, myLocation, setSearchValue }

  function displayToast () {
    if (isPropUpdateSuccess) {
      toast.success('Property updated successfully', {
        toastId: 'PropertyUpdated',
      });
      dispatch(reset());
    } else if (isPropUpdateError) {
      toast.error('Fail to update Property', {
        toastId: 'PropertyUpdatedFailed',
      });
      dispatch(reset());
    }
  }
  return (
    <div className={classes.assets}>
      <PropertyManagementHeader myLocation={location} setSearchValue={setSearchValue} />
      {showProp && <PropertyManagementForm />}
      {!showProp && (
        <>
          {displayToast()}
          <PropertyManagementTable
            setSearchBar={setSearchBar}
            handlehow={() => setShow(!show)}
            closeAdd={() => setShowAdd(false)}
            isShowAdd={setShowAdd}
            locations={modifiedData}
          />
          <Pagination
            totalRows={locationsCount}
            rowPerPage={rowPerPage}
            pageChangeHandler={setCurrentPage}
            changeRowsPerPage={setRowPerPage}
            currentPage={currentPage}
          />
        </>
      )}
    </div>
  );
};

export default PropertyManagement;
