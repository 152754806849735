import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import classes from "../assetsPage/assestsPage.module.css"
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
// import PMTable from './Componets/PMTable';
import PMHeader from './Componets/PMHeader';
import AddPMModal from './Componets/AddPMModal';
import { setTab, closeMaintenanceModalCreate, closeMaintenanceModalUpdate, getMaintenanceById, getPagination, openMaintenanceModalUpdate, reset as PMReset, resetMaintenance, setMaintenance, setCurrentPage, setRowPerPage } from '../../redux/features/maintenance/maintenanceSlice';
import { getChecklistByAuthentication } from '../../redux/features/checklist/checklistSlice';
import { getMaintenanceCategoryByAuthentication } from '../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import { getInventorysByAuthentication } from '../../redux/features/inventory/inventorySlice';
import { closeWorkOrderModalCreate, resetWorkOrder, reset as WOReset, getWorkOrdersByAuthentication } from '../../redux/features/workOrder/workOrderSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import PMTable from './Componets/PMTable';
import PMFilter from './PMFilter';
import { getMaintenanceTypeByAuthentication } from '../../redux/features/maintenanceType/maintenanceTypeSlice';

/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

export default function MaintenancePage() {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getMaintenanceCategoryByAuthentication())
    dispatch(getTeamsByAuthentication())
    dispatch(getLocationsByAuthentication())
    const fmValue = user?.profile?.department_name === "Facilities Management"
    dispatch(getAssetListSearch({assetSearch :"" , bool: fmValue}));
    dispatch(getChecklistByAuthentication());
    dispatch(getInventorysByAuthentication({inventorySeach :"" , bool: fmValue}));
    dispatch(getWorkOrdersByAuthentication())
    dispatch(getMaintenanceTypeByAuthentication())
  }, [])

  const
    checklists
      = useSelector((state) => state.checklists.checklists.results);


  const { maintenances, maintenanceModalCreate,
    // maintenanceModalUpdate,
    isMaintenanceCreateSuccess,
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceCreateError,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError,
    searchValue,
    filterCategory,
    filtertype,
    filterSite,
    filterSublocation,
    currentPage,
    rowPerPage,
    staff,
    filterStatus
  } = useSelector(state => state.maintenance)

  const pmDataCount = useSelector(state => state.maintenance?.maintenances?.count)
  const [iSShowFilter, setISShowFilter] = useState(false)
  const [iSShowCheckboxs, setISShowCheckboxs] = useState(false)

  const [isLoading, setIsLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  useEffect(() => {
    const getDataRows = async () => {
      setIsLoading(true)
      const filters = {
        rowPerPage,
        currentPage,
        searchValue,
        site: filterSite?.value || null,
        sublocation: filterSublocation?.value || null,
        filterCategory: filterCategory?.value || null,
        type: filtertype?.value || null,
        staff: staff?.value || null,
        status: filterStatus?.label && filterStatus?.label !== "All"  ? filterStatus?.label : null
      }
      await dispatch(getPagination(filters))
      setIsLoading(false)
    }
    getDataRows()
  }, [rowPerPage, currentPage, searchValue, filterCategory, filtertype, filterSite, filterSublocation, staff, filterStatus]);



  // useEffect(() => {
  //   if (searchValue !== "") {
  //     setCurrentPage(1)
  //   }
  // }, [searchValue])

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (cell.column.id !== "delete-table-column") {
      dispatch(setMaintenance({}))
      dispatch(resetMaintenance())
      dispatch(setAsset(null))
      dispatch(selectActions.reset())
      await dispatch(getMaintenanceById(selectedRow.id))
      dispatch(openMaintenanceModalUpdate())
      dispatch(setTab("service-tab"))
    }
  };

  const modifiedData = maintenances?.results?.map((d) => {
    return {
      id: d?.id,
      title: d?.title,
      assignedPersons: d?.assigned_to.length > 0 ? d?.assigned_to?.map(a => a) : "—",
      start_date: d?.due_finish,
      created_at: d?.created_at,
      asset: d?.asset?.name || <p>—</p>,
      status: d?.status,
      priority: d?.priority,
      category: d?.category,
      location: d?.location?.site || <p>—</p>,
      completedDate: d?.completed_date || "—",
      createdBy: d?.service_request?.created_by?.role,
      shown_frequency: `${d?.schedule_data ? d?.schedule_data?.frequency_number : d?.schedule?.frequency_number} ${d?.schedule_data ? d?.schedule_data?.frequency : d?.schedule?.frequency}`,
      ticketNumber: d?.ticket_number
    };
  });
  const {
    isWorkOrderCreateSuccess,
    isWorkOrderCreateError
  } = useSelector(state => state.workOrder)

  function displayToast() {
    if (isMaintenanceCreateError) {
      toast.error('Something went wrong Creating Maintenance', {
        toastId: 'CreatedMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceUpdateError) {
      toast.error(' Something went wrong Updating Maintenance', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteError) {
      toast.error(' Something went wrong Deleting Maintenance', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceCreateSuccess) {
      toast.success(' Maintenance Created successfully', {
        toastId: 'Maintenance Created Success',
      });
      dispatch(closeMaintenanceModalCreate())
      dispatch(resetMaintenance())
      dispatch(PMReset())
    } else if (isMaintenanceDeleteSuccess) {
      toast.success(' Maintenance Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(PMReset())
      dispatch(resetMaintenance())
      dispatch(closeMaintenanceModalUpdate())
    }
    else if (isMaintenanceUpdateSuccess) {
      toast.success(' Maintenance Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
      dispatch(PMReset())
    }
    else if (isWorkOrderCreateError) {
      toast.error('Something went wrong Creating WorkOrder', {
        toastId: 'CreatedWorkOrderError',
      });
      dispatch(WOReset())

    } else if (isWorkOrderCreateSuccess) {
      toast.success(' WorkOrder Created successfully', {
        toastId: 'WorkOrder Created Success',
      });
      dispatch(closeWorkOrderModalCreate())
      dispatch(resetWorkOrder())
      dispatch(WOReset())
    }
  }
  return (
    <>
      {displayToast()}
      <div className={classes.assets}>
        <PMHeader setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
        />
        <PMFilter />
        {
          isLoading ? <Loader /> :
            <>
              <PMTable
                iSShowFilter={iSShowFilter}
                setISShowFilter={setISShowFilter}
                iSShowCheckboxs={iSShowCheckboxs}
                setISShowCheckboxs={setISShowCheckboxs}
                getSelectedRowwValues={getSelectedRowwValues}
                meterData={modifiedData || []}
              />
              <Pagination
                totalRows={pmDataCount}
                rowPerPage={rowPerPage}
                pageChangeHandler={setCurrentPage}
                changeRowsPerPage={setRowPerPage}
                currentPage={currentPage}
              />
            </>
        }
      </div>
      {maintenanceModalCreate && <AddPMModal
        openModalCreate={maintenanceModalCreate}
        closeModalCreate={() => dispatch(closeMaintenanceModalCreate())}
        checklistData={checklists}
      />}
      {/* { maintenanceModalUpdate && <EditPMModal 
          openModalUpdate = {maintenanceModalUpdate} 
          closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())}
        />} */}
    </>
  );
}