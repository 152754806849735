/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */



import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faX, faPenToSquare, faCropSimple } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import ReactCropper from 'react-cropper';
import send from "../../../../assets/img/send-svgrepo-com (2).svg";
import { setAssignedTo, setCategory, setPriority, setDescription, setDueFinish, setTitle, setFiles, getAttachment, setMaintenaceType, setIsShowCost, deleteAttachmentTicket, addAttachmentTicket, editAttachmentTicket } from '../../../../redux/features/maintenance/maintenanceSlice';
import { SelectAsset, SelectLocation, SelectPersons, SelectSite } from '../../../../components/select/select';

function Overview({isTechCanEdit }) {
    const { selectedSite, assetInfo } = useSelector(state => state.selectOptions)
    const { locations, sublocations } = useSelector(state => state.location)
    const assetListSearch = useSelector(state => state.asset?.assetListSearch)
    const maintenanceTypes = useSelector(state => state.maintenanceType?.maintenanceTypes);
    const dispatch = useDispatch();

    const {
        title,
        description,
        priority,
        category,
        assigned_to,
        due_finish,
        attachments,
        maintenance,
        files,
        maintenanceType,
        isShowCost
    } = useSelector(state => state.maintenance)

    const [image, setImage] = useState(null);
    const [caption, setCaption] = useState("");
    const [preview, setPreview] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalClass, setModalClass] = useState("");
    const [uploads, setUploads] = useState([]);
    const [editIndex, setEditIndex] = useState(null); // Index of the item being edited
    const [attachmentId, setAttachmentId] = useState(null); // Index of the item being edited


    const [cropData, setCropData] = useState('#');
    const [crop, setCrop] = useState(false);
    const [originalImage, setOriginalImage] = useState(false);
    const cropperRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = (event) => {
                const imageURL = event.target.result
                const photo = document.createElement("img")
                photo.src = imageURL
                photo.onload = (imageEvent) => {
                    const canvas = document.createElement("canvas")
                    const WIDTH = 800
                    const ratio = WIDTH / imageEvent.target.width
                    canvas.width = WIDTH
                    canvas.height = imageEvent.target.height * ratio
                    const context = canvas.getContext("2d")
                    context.drawImage(photo, 0, 0, canvas.width, canvas.height)
                    const newImageURL = context.canvas.toDataURL("image/jpeg", 100)
                    context.canvas.toBlob((blob) => {
                        setImage(blob);
                    })
                    setPreview(newImageURL);
                    setOriginalImage(newImageURL);
                }
                setModalClass("modal--open");
                setModalIsOpen(true);
            };
            setEditIndex(null); // Reset edit index
        }
    };


    const handleEditImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = (event) => {
                const imageURL = event.target.result
                const photo = document.createElement("img")
                photo.src = imageURL
                photo.onload = (imageEvent) => {
                    const canvas = document.createElement("canvas")
                    const WIDTH = 800
                    const ratio = WIDTH / imageEvent.target.width
                    canvas.width = WIDTH
                    canvas.height = imageEvent.target.height * ratio
                    const context = canvas.getContext("2d")
                    context.drawImage(photo, 0, 0, canvas.width, canvas.height)
                    const newImageURL = context.canvas.toDataURL("image/jpeg", 100)
                    context.canvas.toBlob((blob) => {
                        setImage(blob);
                    })
                    setPreview(newImageURL);
                    setOriginalImage(newImageURL);
                }
            };
        }
    };

    const handleCaptionChange = (e) => {
        setCaption(e.target.value);
    };

    const cropImage = () => {
        setCrop(true);
        if (editIndex) {
            console.log("editIndex", editIndex);

        }
    };

    const getCropData = (e) => {
        e.preventDefault();
        if (cropperRef.current?.cropper !== 'undefined') {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                setImage(blob)
                setPreview(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            }, 'image/jpeg');
            setCrop(false);
        }
    };

    const handleSubmit = () => {
        if (editIndex !== null) {
            // Update existing upload
            const updatedUploads = attachments?.map((upload, index) =>
                index === editIndex
                    ? {
                        ...upload,
                        image: image ? image : null,
                        file: image ? image.name : upload?.name,
                        caption,
                        preview
                    }
                    : upload
            );
            setUploads(updatedUploads);
            // handelSubmit(e, [updatedUploads[editIndex]])

            const formData = new FormData()
            if (image) {
                formData.append("photo", image, `${new Date().toISOString()}.png`)
            }
            formData.append("caption", caption)
            dispatch(editAttachmentTicket({ ticketId: maintenance?.id, attachmentId, formData }))
        } else {
            // Create a new upload
            const newUpload = {
                image,
                file: image.name,
                caption,
                preview,
            };
            setUploads([...uploads, newUpload]);
            // handelSubmit(e, [newUpload])
            const formData = new FormData()
            formData.append("photo", newUpload.image, `${new Date().toISOString()}.png`)
            formData.append("caption", newUpload.caption)
            console.log(image);

            dispatch(addAttachmentTicket({ ticketId: maintenance?.id, formData }))
        }
        // Close the modal with animation
        setModalClass("modal--close");
        setTimeout(() => {
            setModalIsOpen(false);
            // Reset the form fields
            setImage(null);
            setCaption("");
            setPreview("");
            setEditIndex(null); // Reset edit index
        }, 300); // Allow time for fade out
    };

    useEffect(() => {
        dispatch(setFiles(uploads));
    }, [uploads])


    const handleEdit = (index) => {
        const upload = attachments[index];
        // setImage(attachments[index]); // Clear image input
        setCaption(upload?.caption || "");
        setPreview(upload?.photo);
        // setImage(upload?.photo);
        // setOriginalImage(upload?.photo);
        setAttachmentId(upload?.id)
        setModalClass("modal--open");
        setModalIsOpen(true);
        setEditIndex(index);
    };

    function ImageIcon() {
        return <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 4C3 2.89543 3.89543 2 5 2H19C20.1046 2 21 2.89543 21 4V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V4ZM19 4H5V20H19V4ZM10 14.8284L12.5858 17.4142C12.9763 17.8047 13.6095 17.8047 14 17.4142L19 12.4142V15H21V9H15V11H17.5858L13.7071 14.8787L11.1213 12.2929C10.7308 11.9024 10.0976 11.9024 9.70711 12.2929L7 15V17H9V14.8284ZM9 7C9 5.89543 9.89543 5 11 5C12.1046 5 13 5.89543 13 7C13 8.10457 12.1046 9 11 9C9.89543 9 9 8.10457 9 7ZM11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7Z"
                fill="currentColor"
            />
        </svg>
    }

    const allSubLocations = [];

    const maintenanceCategory = useSelector(state => state.maintenanceCategory?.maintenanceCategories)
    const teams = useSelector(state => state.teams?.teams?.results)

    const handelShowCost = () => {
        dispatch(setIsShowCost(!isShowCost));
    };

    const handelTitle = (e) => {
        dispatch(setTitle(e.target.value))
    }
    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }

    const handelPriority = (e) => {
        dispatch(setPriority(e.target.value))
    }


    const handelCategory = (e) => {
        dispatch(setCategory(e.target.value))
    }
    const handelType = e => {
        dispatch(setMaintenaceType(e.target.value));
    };
    const handelDueFinish = (e) => {
        dispatch(setDueFinish(e.target.value))
    }
    const handelAttachments = (e) => {
        dispatch(setFiles([{ file: e.target.files[0] }]))
    }
    const deleteAttachment = async (attachId) => {
        await dispatch(deleteAttachmentTicket(attachId))
        // dispatch(getAttachment(attachments.filter(attach => attach.id !== attachId)))
    }

    const {
        logs,
    } = useSelector(state => state.logTime)
    const { ticketinvs } = useSelector(state => state.ticketinv)
    const totalCost = logs?.reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.labor_cost,
        0,
    )
    const totHours = logs?.reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.hours,
        0,
    );
    const totalMinutes = logs?.reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.minutes,
        0,
    );
    const totalHours = Math.floor((totHours + (totalMinutes / 60))).toFixed()
    const min = totalMinutes % 60

    const totalInvsCost = ticketinvs?.reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.total_cost,
        0,
    );
    const totalCostOFPM = totalCost + totalInvsCost
    return (
        <div className="tab-pane fade active show" id="service-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-service-tab">
            <div className="card-body row">
                <div className="col-lg-12 col-sm-12">
                    <label className="font-weight-normal gcolor">Title <label className="font-danger m-0">*</label></label>
                    <input
                        type="text"
                        className="form-control mb-2 font-weight-normal"
                        name="meter_name"
                        id="meter_name"
                        onChange={handelTitle}
                        value={title}
                        disabled={isTechCanEdit}
                        required
                    />
                </div>
                <div className="col-lg-12 col-sm-12">
                    <label className="font-weight-normal gcolor">Description</label>
                    <textarea
                        className="form-control mb-2"
                        name="description"
                        id="meter_description"
                        placeholder="Add description"
                        onChange={handelDescription}
                        value={description}
                        disabled={isTechCanEdit}
                    >

                    </textarea>
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">
                    <div id="ifAsset" className="col-12 p-0">
                        <label className="font-weight-normal">Assets</label>
                        <SelectAsset width="100%" setAssignedTo={setAssignedTo} assetData={assetListSearch} />
                    </div>
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">
                    <div id="ifLocation" className="col-12 p-0">
                        <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
                        {selectedSite &&
                            <div className="col-12 p-0" id="building_show">
                                <SelectLocation
                                    assetInfo={assetInfo}
                                    subLocation={sublocations?.results}
                                    allSubLocations={allSubLocations}
                                    width="100%"
                                    noStar
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                    <label className="font-weight-normal">Assigned to <span className="font-danger">*</span></label>
                    <div className="col-12 p-0">

                        <SelectPersons teams={teams} assignedTo={assigned_to} onEdit setAssignedTo={setAssignedTo} />
                    </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                    <label className="font-weight-normal">Service Date <label className="font-danger m-0">
                        *</label></label>
                    <input
                        type="datetime-local"
                        className="form-control mb-2"
                        name="service_date"
                        id="meter_service_date"
                        placeholder="End Date"
                        onChange={handelDueFinish}
                        value={due_finish}
                        required
                        // disabled={isTechCanEdit}
                    />
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">
                    <label className="font-weight-normal ">Priority</label>
                    <select className="form-control select-dash custom-select2 mb-2"
                        name="priority2" id="priority2"
                        value={priority}
                        onChange={handelPriority}
                        // disabled={isTechCanEdit}
                    >
                        <option value="" disabled selected >--SELECT--</option>
                        {['High', 'Medium', 'Low'].map(val => (
                            <option
                                value={val}
                                key={val}
                            >{val}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-12 col-sm-12 pt-3">
                    <label className="font-weight-normal">Category <span className="font-danger">*</span></label>
                    <select className="form-control select-dash custom-select2 mb-2"
                        name="category" id="category2"
                        value={category}
                        onChange={handelCategory}
                        // disabled={isTechCanEdit}
                    >
                        <option value="" disabled selected>--SELECT--</option>
                        {maintenanceCategory && maintenanceCategory.length > 0
                            && maintenanceCategory.map(cat => (
                                <option
                                    value={cat.name}
                                    key={cat.id}
                                >{cat.name}</option>
                            ))}
                    </select>
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">
                    <label className="font-weight-normal">Sub Category</label>
                    <select className="form-control select-dash custom-select2 mb-2"
                        name="category" id="category2"
                        onChange={handelType}
                        value={maintenanceType}
                        // disabled={isTechCanEdit}
                    >
                        <option value="" disabled selected>--SELECT--</option>
                        {maintenanceTypes && maintenanceTypes.length > 0
                            && maintenanceTypes.map(type => (
                                <option
                                    value={type.name}
                                    key={type.id}
                                >{type.name}</option>
                            ))}
                    </select>
                </div>
                <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 pb-2 pt-3">
                    <div className="card-header m-0 mb-2 p-0 gcolor">
                        <span>Attachments</span>
                    </div>
                    <div style={{ background: '#f8f8f8' }}>
                        <input
                            type="file"
                            id="file-input"
                            onChange={handleImageChange}
                            multiple
                            name="file-input"
                            accept="image/*"
                        />
                        <label id="file-input-label" htmlFor="file-input">
                            Choose Files
                        </label>
                    </div>
                    <div>
                        {modalIsOpen && <div className={`the-modal ${modalClass}`}>
                            <div className="the-modal-header">
                                {image !== null && <button
                                    type="button"
                                    onClick={cropImage}
                                    className="close-button mr-4"
                                    title="Crop"
                                >
                                    <FontAwesomeIcon icon={faCropSimple} />
                                </button>
                                }
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModalClass("modal--close");
                                        setModalIsOpen(false) // Allow time for fade out
                                        setImage(null);
                                        setCaption("");
                                        setPreview("");
                                        setEditIndex(null); // Reset edit index
                                    }}
                                    className="close-button"
                                    title="Close"
                                >
                                    <FontAwesomeIcon icon={faX} />
                                    {/* <FontAwesomeIcon icon={faTimes} /> */}
                                </button>
                            </div>
                            <div className="preview-container">
                                {preview && (
                                    <img src={preview} alt="Preview" className="image-preview" />
                                )}
                                <div className="w-100">
                                    <div className="caption-container">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleEditImageChange}
                                            id="editFileInput"
                                            className="the-file-input"
                                        />
                                        <label htmlFor="editFileInput" className="the-file-input-label">
                                            <ImageIcon />
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Add a caption..."
                                            value={caption}
                                            onChange={handleCaptionChange}
                                            className="caption-input"
                                        />
                                    </div>
                                    <button type="button" onClick={handleSubmit} className="send-button">
                                        <img src={send} alt="send-icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        }
                        {crop ? (
                            <div className="col new-croper">
                                <ReactCropper
                                    style={{ height: '100%', width: '100%' }}
                                    initialAspectRatio={1}
                                    className="row align-items-center my-auto col-lg-12 col-md-12 col-sm-12 croper"
                                    preview=".img-preview"
                                    src={originalImage}
                                    ref={cropperRef}
                                    viewMode={0.5}
                                    guides
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background
                                    responsive
                                    checkOrientation={false}
                                />
                                <div
                                    className="box"
                                // style={{ width: "50%", float: "right", height: "300px" }}
                                >
                                    <div className="buttons_crop">
                                        <button onClick={() => setCrop(false)} type="button" className="btn btn-danger">
                                            Cancel
                                        </button>

                                        <button onClick={getCropData} type="button" className="btn btn-success">
                                            Crop Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {attachments?.length > 0 && <div className="uploads-container">
                            {attachments?.length > 0 && attachments.map((upload, index) => (
                                <div
                                    key={index}
                                    className="upload-item"
                                >
                                    <img src={upload?.photo} className="upload-file" alt="upload-file" />
                                    <p className="upload-caption" title={upload?.caption}>
                                        {upload.caption?.length > 45 ? `${upload.caption?.slice(0, 45)}...` : upload?.caption}
                                    </p>
                                    <button
                                        disabled={isTechCanEdit}
                                        type="button"
                                        onClick={() => handleEdit(index)}
                                        className="edit-upload-item"
                                    >
                                        <span
                                            className="text font-danger"
                                            data-toggle="modal"
                                            data-target="#modal-attachment"
                                            title={isTechCanEdit ? "You Can't Edit it" : 'Edit Attachment'}
                                        >
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </span>
                                    </button>
                                    <button
                                        disabled={isTechCanEdit}
                                        type="button"
                                        onClick={() => deleteAttachment({ ticketId: maintenance?.id, attachmentId: upload.id })}
                                        className="delete-upload-item"
                                    >
                                        <span
                                            className="text font-danger"
                                            data-toggle="modal"
                                            data-target="#modal-attachment"
                                            title={isTechCanEdit ? "You Can't Delete it" : 'Delete Attachment'}
                                        >
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </span>
                                    </button>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>

                {maintenance.asset && <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3" style={{ borderRadius: "8px !important" }} >
                    <div className="card-header m-0 p-0 gcolor">
                        <span >Asset Details</span>
                    </div>
                    <div className="card-body row p-2">
                        <div className="col-12 medium-title mb-3">
                            <a href={`/assets/${maintenance?.asset?.uuid}`} title="More Asset Details">
                                <span className="col-4"> {maintenance?.asset?.asset_number}</span>
                            </a>
                            <span className="col-8"> {maintenance?.asset?.name}</span>
                        </div>

                        <div className="col-6">
                            <span className="gcolor col-12">Type </span>
                            <span className=""> {maintenance?.asset?.asset_type}</span>
                        </div>
                        <div className="col-6">
                            <span className="gcolor col-12">Category</span>
                            <span className=""> {maintenance?.asset?.category}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <span className="gcolor col-12">Location</span>
                            <span className="">
                                {maintenance?.asset?.location}
                            </span>
                        </div>
                    </div>
                </div>}


                {maintenance.location !== null && <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details" >
                    <div className="card-header"><span >Location details</span>
                    </div>
                    <div className="m-0 p-0 gcolor" id="L-view">
                        <h6 className="title" style={{ color: "red", paddingLeft: "5px" }}>
                            Location
                            <div style={{ color: "black" }}>{maintenance?.location?.site}</div>
                        </h6>
                        {maintenance.sub_location !== null && <ul className="wtree">
                            {maintenance?.sub_location?.building && <ul >
                                <li>
                                    <span style={{ marginRight: "50%" }}>
                                        Building
                                        <div style={{ color: "black" }}>{maintenance?.sub_location?.building}</div>
                                    </span>
                                </li>
                            </ul>}
                            {maintenance?.sub_location?.level && <ul  >
                                <li >
                                    <span style={{ marginRight: "50%" }}>
                                        Level
                                        <div style={{ color: "black" }}>{maintenance?.sub_location?.level}</div>
                                    </span>
                                </li>
                            </ul>}
                            {maintenance?.sub_location?.suite && <ul >
                                <li >
                                    <span style={{ marginRight: "50%" }}>
                                        Suite
                                        <div style={{ color: "black" }}>{maintenance?.sub_location?.suite}</div>
                                    </span>
                                </li>
                            </ul>}
                            {maintenance?.sub_location?.room && <ul >
                                <li >
                                    <span style={{ marginRight: "50%" }}>
                                        Room
                                        <div style={{ color: "black" }}>{maintenance?.sub_location?.room}</div>
                                    </span>
                                </li>
                            </ul>}
                        </ul>
                        }
                    </div>

                </div>
                }
                <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details p-3">
                    <div className="card-header m-0 p-0 gcolor">
                        <span >Summary</span>
                    </div>
                    <div className="card-body m-0 p-0">
                        <div className="row">
                            <div className="col-4 row">
                                <b className="col-12">Total Cost</b>
                                <span style={{ color: "#F63854" }} className="col-12" id="total_ticket_cost">


                                    ${totalCostOFPM.toFixed(2)}

                                </span>
                            </div>

                            <div className="col-5 row">
                                <b className="col-12">Total Time Spend</b>
                                <span className="col-12" style={{ color: "#F63854" }}>
                                    {totalHours} hr {min} Min
                                </span>
                            </div>

                        </div>
                        <div className="col-12 row mt-3">
                            <span >
                                <input type="checkbox" className='cost-status' checked={isShowCost} onChange={handelShowCost} />
                            </span>
                            <span>Include cost in preventive maintenance report</span>
                        </div>
                    </div>
                </div>
                <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 mt-3 p-3" >
                    <div className="card-header m-0 p-0 gcolor">
                        <span >Requestor Details</span>
                    </div>
                    <div className="card-body m-0 p-0">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 row">
                                <b className="col-12">{maintenance?.service_request?.name}</b>
                                <a href={`mailto: ${maintenance?.service_request?.email}`} className="col-12" style={{ color: "#F63854" }}>{maintenance?.service_request?.email}</a>
                            </div>
                            <div className="col-lg-6 col-sm-12 row">
                                <div className="col-12">Phone</div>
                                {maintenance?.service_request?.phone ? <a href={`tel: ${maintenance?.service_request?.phone}`}
                                    className="col-12" style={{ color: "#F63854" }}>{maintenance?.service_request?.phone}</a> :
                                    <span className="col-12" style={{ color: "#F63854" }}>None</span>}
                            </div>

                        </div>
                    </div>
                    <div className="pt-2 my-auto">
                        <span ><b>Issued on</b> {moment(maintenance?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Overview
