/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TeamService from './teamService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  teams: [],
  custodians: [],
  team: {},
  departmentManagers: [],
  searchValue: '',

    // pagination
    currentPage: 1,
    rowPerPage: 10,

  // modals
  teamModalDelete: false,
  teamModalUpdate: false,
  teamModalCreate: false,

  // success
  isTeamCreateSuccess: false,
  isTeamGetSuccess: false,
  isTeamDeleteSuccess: false,
  isTeamActivateSuccess: false,
  isTeamDeactivateSuccess: false,
  isTeamUpdateSuccess: false,

  // loading
  isTeamCreateLoading: false,
  isTeamGetLoading: false,
  isTeamDeleteLoading: false,
  isTeamUpdateLoading: false,
  isTeamActivateLoading: false,
  isTeamDeactivateLoading: false,

  // error
  isTeamGetError: false,
  isTeamCreateError: false,
  isTeamDeleteError: false,
  isTeamUpdateError: false,
  isTeamActivateError: false,
  isTeamDeactivateError: false,
};

export const createTeam = createAsyncThunk('createTeam', async (data, thunkAPI) => {
  const create = await smartTryCatch(TeamService.createTeam, data, thunkAPI);
  return create;
});

export const getTeamsByAuthentication = createAsyncThunk(
  'getTeamsByAuthentication',
  async (data, thunkAPI) => {
    const getTeams = await smartTryCatch(TeamService.getTeamByAuthentication, data, thunkAPI);
    return getTeams;
  },
);

export const getResponsibleEmployeesByAuthentication = createAsyncThunk(
  'getResponsibleEmployeeByAuthentication',
  async (data, thunkAPI) => {
    const getTeams = await smartTryCatch(TeamService.getResponsibleEmployeeByAuthentication, data, thunkAPI);
    return getTeams;
  },
);

export const getTeamById = createAsyncThunk('getTeamById', async (data, thunkAPI) => {
  const getTeam = await smartTryCatch(TeamService.getTeamById, data, thunkAPI);
  return getTeam;
});

export const deleteTeam = createAsyncThunk('deleteTeam', async (data, thunkAPI) => {
  const deleteTeamReq = await smartTryCatch(TeamService.deleteTeam, data, thunkAPI);
  return deleteTeamReq;
});

export const updateTeam = createAsyncThunk('updateTeam', async (data, thunkAPI) => {
  const update = await smartTryCatch(TeamService.updateTeam, data, thunkAPI);
  return update;
});

export const activateTeam = createAsyncThunk('activateTeam', async (data, thunkAPI) => {
  const activate = await smartTryCatch(TeamService.activateTeam, data, thunkAPI);
  return activate;
});

export const deactivateTeam = createAsyncThunk('teamDeactivate', async (data, thunkAPI) => {
  const deactivate = await smartTryCatch(TeamService.deactivateTeam, data, thunkAPI);
  return deactivate;
});

export const getDepartmentManagers = createAsyncThunk('getDepartmentManagers', async (data, thunkAPI) => {
  const getDepartmentManagerRes = await smartTryCatch(TeamService.getDepartmentManagers, data, thunkAPI);
  return getDepartmentManagerRes;
});
export const getPagination = createAsyncThunk('getPaginationForteam', async (data, thunkAPI) => {
  const getteam = await smartTryCatch(TeamService.getPagination, data, thunkAPI);
  return getteam;
});

export const teamSlice = createSlice({
  name: 'teams',
  initialState: initState,
  reducers: {
    reset: state => {
      state.isTeamCreateError = false;
      state.isTeamCreateSuccess = false;
      state.isTeamCreateLoading = false;
      state.isTeamGetError = false;
      state.isTeamGetSuccess = false;
      state.isTeamGetLoading = false;
      state.isTeamDeleteError = false;
      state.isTeamDeleteSuccess = false;
      state.isTeamDeleteLoading = false;
      state.isTeamUpdateError = false;
      state.isTeamUpdateSuccess = false;
      state.isTeamUpdateLoading = false;
      state.isTeamActivateError = false;
      state.isTeamActivateSuccess = false;
      state.isTeamActivateLoading = false;
      state.isTeamDeactivateError = false;
      state.isTeamDeactivateSuccess = false;
      state.isTeamDeactivateLoading = false;
      // state.team = null;
      // state.teams = [];
    },
    openTeamModalCreate: state => {
      state.teamModalCreate = true;
    },
    closeTeamModalCreate: state => {
      state.teamModalCreate = false;
    },
    openTeamModalUpdate: state => {
      state.teamModalUpdate = true;
    },
    closeTeamModalUpdate: state => {
      state.teamModalUpdate = false;
    },
    openTeamModalDelete: state => {
      state.teamModalDelete = true;
    },
    closeTeamModalDelete: state => {
      state.teamModalDelete = false;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setTeamActivate: (state, action) => {
      state.team = action.payload;
    },
    setTeamDeactivate: (state, action) => {
      state.team = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },

  },
  extraReducers: builder => {
    builder
      .addCase(createTeam.pending, state => {
        state.isTeamCreateLoading = true;
        state.isTeamCreateSuccess = false;
        state.isTeamCreateError = false;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.isTeamCreateLoading = false;
        state.isTeamCreateSuccess = true;
        state.isTeamCreateError = false;
        state.teams.results = [action.payload , ...state.teams.results];
        state.teams.count += 1;
      })
      .addCase(createTeam.rejected, state => {
        state.isTeamCreateLoading = false;
        state.isTeamCreateSuccess = false;
        state.isTeamCreateError = true;
      })
      .addCase(getTeamsByAuthentication.pending, state => {
        state.isTeamGetLoading = true;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = false;
      })
      .addCase(getTeamsByAuthentication.fulfilled, (state, action) => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = true;
        state.isTeamGetError = false;
        state.teams = action.payload;
      })
      .addCase(getTeamsByAuthentication.rejected, state => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = true;
      })
      .addCase(getDepartmentManagers.pending, state => {
        state.isTeamGetLoading = true;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = false;
      })
      .addCase(getDepartmentManagers.fulfilled, (state, action) => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = true;
        state.isTeamGetError = false;
        state.departmentManagers = action.payload;
      })
      .addCase(getDepartmentManagers.rejected, state => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = true;
      })
      .addCase(getPagination.pending, state => {
        state.isTeamGetLoading = true;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = false;
      })
      .addCase(getPagination.fulfilled, (state, action) => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = true;
        state.isTeamGetError = false;
        state.teams = action.payload;
      })
      .addCase(getPagination.rejected, state => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = true;
      })
      .addCase(getTeamById.pending, state => {
        state.isTeamGetLoading = true;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = false;
      })
      .addCase(getTeamById.fulfilled, (state, action) => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = true;
        state.isTeamGetError = false;
        state.teams = action.payload;
      })
      .addCase(getTeamById.rejected, state => {
        state.isTeamGetLoading = false;
        state.isTeamGetSuccess = false;
        state.isTeamGetError = true;
      })
      .addCase(deleteTeam.pending, state => {
        state.isTeamDeleteLoading = true;
        state.isTeamDeleteSuccess = false;
        state.isTeamDeleteError = false;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.isTeamDeleteLoading = false;
        state.isTeamDeleteSuccess = true;
        state.isTeamDeleteError = false;
        state.teams.results = state.teams.results.filter(team => team.id !== action.payload);
        state.teams.count -= 1;
      })
      .addCase(deleteTeam.rejected, state => {
        state.isTeamDeleteLoading = false;
        state.isTeamDeleteSuccess = false;
        state.isTeamDeleteError = true;
      })
      .addCase(updateTeam.pending, state => {
        state.isTeamUpdateLoading = true;
        state.isTeamUpdateSuccess = false;
        state.isTeamUpdateError = false;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.isTeamUpdateLoading = false;
        state.isTeamUpdateSuccess = true;
        state.isTeamUpdateError = false;
        state.teams.results = state.teams.results.map(team =>
          team.id === action.payload.id ? action.payload : team,
        );
      })
      .addCase(updateTeam.rejected, state => {
        state.isTeamUpdateLoading = false;
        state.isTeamUpdateSuccess = false;
        state.isTeamUpdateError = true;
      })
      .addCase(activateTeam.pending, state => {
        state.isTeamActivateLoading = true;
        state.isTeamActivateSuccess = false;
        state.isTeamActivateError = false;
      })
      .addCase(activateTeam.fulfilled, (state, action) => {
        state.isTeamActivateLoading = false;
        state.isTeamActivateSuccess = true;
        state.isTeamActivateError = false;
        const foundIndex = state.teams.results.findIndex(x => x.id === action.payload.id);
        state.teams.results[foundIndex].is_active = action.payload.is_active;
      })
      .addCase(activateTeam.rejected, state => {
        state.isTeamActivateLoading = false;
        state.isTeamActivateSuccess = false;
        state.isTeamActivateError = true;
      })
      .addCase(deactivateTeam.pending, state => {
        state.isTeamDeactivateLoading = true;
        state.isTeamDeactivateSuccess = false;
        state.isTeamDeactivateError = false;
      })
      .addCase(deactivateTeam.fulfilled, (state, action) => {
        state.isTeamDeactivateLoading = false;
        state.isTeamDeactivateSuccess = true;
        state.isTeamDeactivateError = false;
        const foundIndex = state.teams.results.findIndex(x => x.id === action.payload.id);
        state.teams.results[foundIndex].is_active = action.payload.is_active;
      })
      .addCase(deactivateTeam.rejected, state => {
        state.isTeamDeactivateLoading = false;
        state.isTeamDeactivateSuccess = false;
        state.isTeamDeactivateError = true;
      });
  },
});

export const {
  reset,
  openTeamModalCreate,
  closeTeamModalCreate,
  openTeamModalUpdate,
  closeTeamModalUpdate,
  openTeamModalDelete,
  setTeam,
  closeTeamModalDelete,
  setSearchValue,
  setCurrentPage,
  setRowPerPage 
} = teamSlice.actions;

export default teamSlice.reducer;
