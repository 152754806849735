/* eslint-disable no-unneeded-ternary */
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getLocationById = async LocationId => {
  const res = await http.get(`${BASE_URL}/location/${LocationId}/`);

  return assert(res, res.data, 'Retrieval of location  by its ID failed', res);
};
const updateLocation = async LocationData => {
  const res = await http.put(`${BASE_URL}/location/${LocationData.id}/`, LocationData.data);

  return assert(res, res.data, 'Update of location  failed', res);
};
const deleteTenantAttachment = async data => {
  const res = await http.delete(`${BASE_URL}/delete-tenant-attachment/${data.fileId}/${data.tenId}/`);

  return assert(res, res.data, 'Update of location  failed', res);
};
const PropertyManagementService = {
  getLocationById,
  updateLocation,
  deleteTenantAttachment
};

export default PropertyManagementService;
