import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify"
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import classes from "../assetsPage/assestsPage.module.css"
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
import WOTable from './Componets/WOTable';
import WOHeader from './Componets/WOHeader';
import AddWOModal from './Componets/AddWOModal';
// import EditWOModal from './Componets/EditWOModal';
import { setTab, closeWorkOrderModalCreate, closeWorkOrderModalUpdate, getWorkOrderById, getPagination, openWorkOrderModalUpdate, reset as WOReset, resetWorkOrder, setWorkOrder, setCurrentPage, setRowPerPage, getWorkOrderSummary } from '../../redux/features/workOrder/workOrderSlice';
import { getChecklistByAuthentication } from '../../redux/features/checklist/checklistSlice';
import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';
import { getInventorysByAuthentication } from '../../redux/features/inventory/inventorySlice';
import { closeMaintenanceModalUpdate, resetMaintenance, reset as PMReset } from '../../redux/features/maintenance/maintenanceSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import WOFilter from './WOFilter';
import { getWorkOrderTypeByAuthentication } from '../../redux/features/workOrderType/workOrderTypeSlice';
import WOAcceptAndDeclineTabs from './Componets/WOAcceptAndDeclineTabs';
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

export default function WorkOrderPage() {

  const { workOrders, workOrderModalCreate,
    // workOrderModalUpdate,
    isWorkOrderCreateSuccess,
    isWorkOrderDeleteSuccess,
    isWorkOrderUpdateSuccess,
    isWorkOrderCreateError,
    isWorkOrderDeleteError,
    isWorkOrderUpdateError,
    searchValue,
    site,
    sublocation,
    filterCategory,
    type,
    currentPage,
    rowPerPage,
    staff,
    filterStatus,
    isWorkOrderGetStatus,
    statusType
  } = useSelector(state => state.workOrder)
  const {
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError} = useSelector(state => state.maintenance)
const woDataCount = useSelector(state => state.workOrder?.workOrders?.count)
// const {  idOfSite, idOfLocation} = useSelector(
//   state => state.selectOptions,
// );
const [iSShowFilter, setISShowFilter] = useState(false)
const [iSShowCheckboxs, setISShowCheckboxs] = useState(false)

  const dispatch = useDispatch();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true)

  const getDataRows = async () => {
    const filters =
    {
      rowPerPage,
      currentPage,
      searchValue,
      site: site?.value || null,
      sublocation: sublocation?.value || null,
      filterCategory: filterCategory?.value || null,
      type: type?.value || null,
      staff: staff?.value || null,
      status: filterStatus?.label && filterStatus?.label !== "All" ? filterStatus?.label : null
    }
    await dispatch(getPagination(filters))
    setIsLoading(false)
  }

  useEffect(() => {
    getDataRows()
  }, [rowPerPage, currentPage, searchValue, site, sublocation, filterCategory, type, staff, filterStatus]);

  const {user} = useSelector(state => state.user)

  useEffect(() => {
    if (isWorkOrderGetStatus) {
      toast.success(`Request has been ${statusType}`, {
        toastId: 'WorkOrder status',
      });
      dispatch(getWorkOrderSummary())
      dispatch(closeWorkOrderModalUpdate())
      getDataRows()
      dispatch(WOReset())
    }
  }, [isWorkOrderGetStatus])


  async function getSummary() {
    await dispatch(getWorkOrderSummary())
   }

  useEffect(() => {
    dispatch(getWorkOrderCategoryByAuthentication())
    dispatch(getWorkOrderTypeByAuthentication())
    dispatch(getTeamsByAuthentication())
    dispatch(getLocationsByAuthentication())
    const fmValue = user?.profile?.department_name === "Facilities Management"
    dispatch(getAssetListSearch({assetSearch :"" , bool: fmValue}));
    dispatch(getChecklistByAuthentication());
    dispatch(getInventorysByAuthentication({inventorySeach :"" , bool: fmValue}));
    dispatch(getWorkOrderSummary());
}, [])


  // useEffect(() => {
  //   const getAssetDataRows = async () => {
  //     const filterData = {
  //       site: idOfSite || null,
  //       sublocation: idOfLocation || null,
  //       category: null
  //     }
  //     // if(idOfSite || idOfLocation){
  //     await dispatch(getAssetsByRows({ rowPerPage: 10, currentPage: 1, assetSearchValue: "", filterData }));
  //     // }
  //   }
  //   getAssetDataRows()
  // }, [idOfSite, idOfLocation])

  const
    checklists
      = useSelector((state) => state.checklists.checklists.results);


  // useEffect(() => {
  //   if (searchValue !== "") {
  //     setCurrentPage(1)
  //   }
  // }, [searchValue])

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (cell.column.id !== "delete-table-column") {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getWorkOrderById(selectedRow.id))
      dispatch(openWorkOrderModalUpdate())
      dispatch(setTab("service-tab"))
    }
  };


  const modifiedData = workOrders?.results?.map((d) => {
    return {
      id: d.id,
      title: d.title,
      assignedPersons: d?.assigned_to.length > 0 ? d?.assigned_to : "—",
      // start_date: moment.utc(d?.due_finish).format("YYYY-MM-DDTHH:mm"),
      start_date: d?.due_finish,
      created_at: d?.created_at,
      asset: d?.asset?.name || "—",
      status: d.status || "—",
      priority: d.priority,
      category: d.category,
      location: d?.location?.site || "—",
      // completedDate:  moment.utc(d?.completed_date).format("YYYY-MM-DDTHH:mm") || "—",
      completedDate: d?.completed_date || "—",
      createdBy: d?.service_request?.created_by?.role,
      ticketNumber: d?.ticket_number
    };
  });

  function displayToast() {
    if (isWorkOrderCreateError) {
      toast.error('Something went wrong Creating WorkOrder', {
        toastId: 'CreatedWorkOrderError',
      });
      dispatch(WOReset())
    } else if (isWorkOrderUpdateError) {
      toast.error(' Something went wrong Updating WorkOrder', {
        toastId: 'UpdatingWorkOrderError',
      });
      dispatch(WOReset())
    } else if (isWorkOrderDeleteError) {
      toast.error(' Something went wrong Deleting WorkOrder', {
        toastId: 'DeletingWorkOrderError',
      });
      dispatch(WOReset())
    } else if (isWorkOrderCreateSuccess) {
      toast.success(' WorkOrder Created successfully', {
        toastId: 'WorkOrder Created Success',
      });
      getSummary()
      dispatch(closeWorkOrderModalCreate())
      dispatch(resetWorkOrder())
      dispatch(WOReset())
    } else if (isWorkOrderDeleteSuccess) {
      toast.success(' WorkOrder Deleted successfully', {
        toastId: 'DeletedWorkOrderError',
      });
      dispatch(WOReset())
      dispatch(resetWorkOrder())
      dispatch(closeWorkOrderModalUpdate())
    }
    else if (isWorkOrderUpdateSuccess) {
      toast.success(' WorkOrder Updated successfully', {
        toastId: 'UpdatedWorkOrderError',
      });
      dispatch(WOReset())
    }
    else if (isMaintenanceUpdateError) {
      toast.error(' Something went wrong Updating Maintenance', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteError) {
      toast.error(' Something went wrong Deleting Maintenance', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteSuccess) {
      toast.success(' Maintenance Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(PMReset())
      dispatch(resetMaintenance())
      dispatch(closeMaintenanceModalUpdate())
    } else if (isMaintenanceUpdateSuccess) {
      toast.success(' Maintenance Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
      dispatch(PMReset())
    }
  }

  return (
    <>
      {displayToast()}
      <div className={classes.assets}>
        <WOHeader setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
        />
        {
          isLoading ? <Loader /> :
            <>
              <WOFilter />
              <WOAcceptAndDeclineTabs />
              <WOTable
                iSShowFilter={iSShowFilter}
                setISShowFilter={setISShowFilter}
                iSShowCheckboxs={iSShowCheckboxs}
                setISShowCheckboxs={setISShowCheckboxs}
                getSelectedRowwValues={getSelectedRowwValues}
                meterData={modifiedData || []}
              />
              <Pagination
                totalRows={woDataCount}
                rowPerPage={rowPerPage}
                pageChangeHandler={setCurrentPage}
                changeRowsPerPage={setRowPerPage}
                currentPage={currentPage}
              />
            </>
        }
      </div>
      {workOrderModalCreate && <AddWOModal
        openModalCreate={workOrderModalCreate}
        closeModalCreate={() => dispatch(closeWorkOrderModalCreate())}
        checklistData={checklists}
      />}
      {/* {workOrderModalUpdate && <EditWOModal 
          openModalUpdate = {workOrderModalUpdate} 
          closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}
        />} */}
    </>
  );
}