/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import PropertyManagementService from './PropertyManagementService';

const initialState = {
  showProp: false,
  singleLocation: {},

  // state for location
  isSingleLocationLoading: false,
  isSingleLocationSuccess: false,
  isSingleLocationError: false,

  // update the property
  isPropUpdateLoading: false,
  isPropUpdateSuccess: false,
  isPropUpdateError: false,

  isAttachmentDeletedLoading: false,
  isAttachmentDeletedSuccess: false,
  isAttachmentDeletedError: false,
};

export const getSingleLocationByID = createAsyncThunk('getLocationById', async (data, thunkAPI) => {
  const res = await smartTryCatch(PropertyManagementService.getLocationById, data, thunkAPI);
  return res;
});

export const updateLocation = createAsyncThunk('updateLocation', async (data, thunkAPI) => {
  const res = await smartTryCatch(PropertyManagementService.updateLocation, data, thunkAPI);
  return res;
});

export const deleteTenantAttachment = createAsyncThunk('deleteTenantAttachment', async (data, thunkAPI) => {
  const res = await smartTryCatch(PropertyManagementService.deleteTenantAttachment, data, thunkAPI);
  return res;
});

export const propertyManagementSlice = createSlice({
  name: 'property',
  initialState: initialState,
  reducers: {
    reset: state => {
      state.isPropUpdateLoading = false;
      state.isPropUpdateSuccess = false;
      state.isPropUpdateError = false;
      state.isAttachmentDeletedLoading= false;
      state.isAttachmentDeletedSuccess= false;
      state.isAttachmentDeletedError= false;
    },
    setSingleLocation: (state, action) => {
      state.singleLocation = action.payload;
    },
    setShowProp: (state, action) => {
      state.showProp = true;
    },
    setCloseProp: (state, action) => {
      state.showProp = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSingleLocationByID.pending, state => {
        state.isSingleLocationLoading = true;
        state.isSingleLocationSuccess = false;
        state.isSingleLocationError = false;
      })
      .addCase(getSingleLocationByID.fulfilled, (state, action) => {
        state.isSingleLocationLoading = false;
        state.isSingleLocationSuccess = true;
        state.isSingleLocationError = false;
        state.singleLocation = action.payload;
      })
      .addCase(getSingleLocationByID.rejected, (state, action) => {
        state.isSingleLocationLoading = false;
        state.isSingleLocationSuccess = false;
        state.isSingleLocationError = true;
      })
      .addCase(updateLocation.pending, (state, action) => {
        state.isPropUpdateLoading = true;
        state.isPropUpdateSuccess = false;
        state.isPropUpdateError = false;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.isPropUpdateLoading = false;
        state.isPropUpdateSuccess = true;
        state.isPropUpdateError = false;
        state.singleLocation = action.payload;
      })
      .addCase(updateLocation.rejected, (state) => {
        state.isPropUpdateLoading = false;
        state.isPropUpdateSuccess = false;
        state.isPropUpdateError = true;
      })
      .addCase(deleteTenantAttachment.pending, (state, ) => {
        state.isAttachmentDeletedLoading= true;
        state.isAttachmentDeletedSuccess= false;
        state.isAttachmentDeletedError= false;
      })
      .addCase(deleteTenantAttachment.fulfilled, (state, action) => {
        state.isAttachmentDeletedLoading= false;
        state.isAttachmentDeletedSuccess= true;
        state.isAttachmentDeletedError= false;
      }).addCase(deleteTenantAttachment.rejected, (state) => {
        state.isAttachmentDeletedLoading= false;
        state.isAttachmentDeletedSuccess= false;
        state.isAttachmentDeletedError= true;
      })
  },
});

export const { reset, setShowProp, setCloseProp, setSingleLocation } =
  propertyManagementSlice.actions;

export default propertyManagementSlice.reducer;
