/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAssetsByRows,
  reset,
  resetAsset,
  setAsset,
  setCurrentPage,
  setRowPerPage,
} from '../../redux/features/asset/assetSlice';
import AssetsTable from '../../components/assetsTable/assetsTable';
import classes from './assestsPage.module.css';
import AssetHeader from './assetHeader';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import AssetFilter from './AssetFilter/AssetFilter';

/* eslint-disable no-nested-ternary */

export default function AssetsPage () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { site, sublocation, category , currentPage , rowPerPage } = useSelector(state => state.asset);
  const assetsData = useSelector(state => state.asset?.assets?.results);
  const assetsDataCount = useSelector(state => state.asset?.assets?.count);
  const [iSShowFilter, setISShowFilter] = useState(false);
  const [iSShowCheckboxs, setISShowCheckboxs] = useState(false);

  const [assets, setAssets] = useState(assetsData || []);

  const [isLoading, setIsLoading] = useState(false);

  const [assetTab, setAssetTab] = useState(JSON.parse(localStorage.getItem('activeDepartment')) || '1');

  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  const {
    assetSearchValue,
    isAssetGetLoading,
    isAssetImported,
    isAssetImportedLoading,
    isAssetImportedError,
  } = useSelector(state => state.asset);

  // useEffect(() => {
  //   if (assetSearchValue !== '') {
  //     dispatch(setCurrentPage(1));
  //   }
  // }, [assetSearchValue]);

  useEffect(() => {
    const getDataRows = async () => {
      setIsLoading(true);
      const filterData = {
        site : site?.value || null,
        sublocation : sublocation?.value || null,
        category : category?.value || null,
        department : (assetTab === "from ms" ?  null : assetTab) || null,
        from_ms : assetTab === "from ms" ? "True" : null,
      };
      await dispatch(getAssetsByRows({ rowPerPage, currentPage, assetSearchValue, filterData }));
      setIsLoading(false);
    };
    getDataRows();
  }, [rowPerPage, currentPage, assetSearchValue, site, sublocation, category, assetTab]);
  useEffect(() => {
    setAssets(assetsData);
  }, [assetsData]);

  const getSelectedRowwValues = async (selectedRow, cell) => {
    dispatch(selectActions.reset());
    if (cell.column.id !== 'expander') {
      dispatch(resetAsset());
      history.push(`assets/${selectedRow?.uuid}`);
    }
  };

  function displayToast () {
    if (isAssetImportedError) {
      toast.error('Something went wrong while Importing Asset', {
        toastId: 'DeletedAssetError',
      });
      dispatch(reset());
    } else if (isAssetImported) {
      toast.success(' Asset Imported successfully', {
        toastId: 'Asset Imported Success',
      });
      dispatch(reset());
    }
  }
  const handelReset = () => {
    dispatch(setAsset({}));
    dispatch(selectActions.reset());
  };

  return isAssetImportedLoading ? (
    <Loader />
  ) : (
    <>
      {displayToast()}
      <div className={classes.assets}>
        <AssetHeader
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
          assets={assets}
          assetTab={assetTab} 
          setAssetTab={setAssetTab}
        />
        {isAssetGetLoading ? (
          <Loader />
        ) : (
          <>
            <AssetFilter />
            <AssetsTable
              iSShowFilter={iSShowFilter}
              setISShowFilter={setISShowFilter}
              iSShowCheckboxs={iSShowCheckboxs}
              setISShowCheckboxs={setISShowCheckboxs}
              getSelectedRowwValues={getSelectedRowwValues}
              assets={assets || []}
              isLoading={isLoading}
              handelReset={handelReset}
            />
            <Pagination
              totalRows={assetsDataCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
    </>
  );
}
