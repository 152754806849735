/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase  */

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import classes from './select.module.css';
import {
  getTeamsByAuthentication,
  getPagination as teamsPagination,
} from '../../redux/features/teams/teamSlice';
import { getAssetListSearch } from '../../redux/features/asset/assetSlice';
import {
  getLocationByPagination,
  getLocationsByAuthentication,
  getPagination as locationsPagination,
} from '../../redux/features/location/locationSlice';
import {
  getPagination as checklistPagination,
  getChecklistByAuthentication,
} from '../../redux/features/checklist/checklistSlice';
import { getMetersByPagination } from '../../redux/features/meter/meterSlice';

export function SelectWOType({ width, handelTicketType }) {
  const dispatch = useDispatch();
  const onCloseSelect = () => {
    dispatch()
  }
  const openSite = true;
  return (
    <div className={classes.select} style={{ width: width && width }}>
      <div onClick={onCloseSelect} className={openSite ? classes.overlay : ""}>
        <ul onClick={handelTicketType} className={classes.listItems}>
          <li id="assetCheck">
            Assets
          </li>
          <li id="locationCheck">
            Location
          </li>
          <li id="generalCheck">
            General
          </li>
        </ul>
      </div>
    </div>
  );
}
export function SelectSite({ noStar, width, locations, assetInfo = null  , comeFromAsset}) {
  const dispatch = useDispatch();
  const { selectedSite, openSite, valueOfSite } = useSelector(
    state => state.selectOptions,
  );
  const {user } = useSelector(
    state => state.user,
  );
  // const asset = useSelector(state => state.asset?.asset);
  const changeHandler = e => {
    const valueOfInput = e.target.value;
    dispatch(selectActions.searchOnSite({valueOfInput , locations}))
    dispatch(
      getLocationByPagination({ currentPage: 1, rowPerPage: '20', searchValue: valueOfInput, from_ms: assetInfo?.from_ms  }),
    );
  };
  useEffect(()=>{
    if(assetInfo?.location?.id){
      const {id,site} = assetInfo?.location 
      dispatch(selectActions.chooseSite({ id,selected:site }));
      dispatch(
        locationsPagination({ locationID: id, currentPage: 1, rowPerPage: '20', searchValue: '' }),
      );
    }
  }, [assetInfo])
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseLocation({ id: null, selected: '' }))
    dispatch(selectActions.chooseSite({ id, selected }));
    if (id !== null && selected !== null ) {
      dispatch(
        locationsPagination({ locationID: id, currentPage: 1, rowPerPage: '20', searchValue: '' }),
      );
    }
  };

  const listItemsClick = () => {
    if (comeFromAsset && assetInfo?.from_ms && user?.profile?.department_name === "General Administration") {
      dispatch(selectActions.listItemsClickedSite());
    }else if (!comeFromAsset || !assetInfo?.from_ms){
      dispatch(selectActions.listItemsClickedSite());
    }
  }

  const onCloseSelect = () => {
    dispatch(selectActions.closeSiteSelect());
  };
  return (
    <div style={{ width: width && width }} className={classes.select}>
      <div onClick={onCloseSelect} className={openSite ? classes.overlay : ''} />
      <p>Location {!noStar && <span> *</span>} </p>
      <div
        style={comeFromAsset && assetInfo?.from_ms && user?.profile?.department_name !== "General Administration" ? { backgroundColor: "#e9ecefda" } : {}}
        onClick={listItemsClick}
        className={!openSite ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {selectedSite ? selectedSite : '--SELECT--'}
        <button
          style={noStar ? { marginRight: '10px' } : {}}
          className={openSite ? classes.rotateIcon : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openSite && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openSite && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfSite ? (
            locations.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) 
            : (
              <>
              {(selectedSite || assetInfo?.location?.site) && !valueOfSite && <li
              onClick={() => {
                clickHandler(null, null);
              }}
                >
                  --SELECT--
                </li>
                }
              {locations.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(opt.id, opt.site);
                  }}
                  key={opt.id}
                >
                  {opt.site}
                </li>
              ))}
              </>
            )
          ) 
          : (
            <>
                {(selectedSite || assetInfo?.location?.site) && !valueOfSite && <li
                onClick={() => {
                  clickHandler(null, null);
                }}
                >
                  --SELECT--
                </li>
                }
            {locations?.map(opt => (
              <li
                onClick={() => {
                  clickHandler(opt.id, opt.site);
                }}
                key={opt.id}
              >
                {opt.site}
              </li>
            ))}
            </>
          )
          }
        </ul>
      )}
    </div>
  );
}

export function SelectLocation({ width, subLocation, allSubLocations, noStar, assetInfo = null }) {
  const dispatch = useDispatch();
  const {
    idOfSite,
    valueOfLocation,
    // optionsLocation,
    selectedLocation,
    openLocation,
    // selectedSite,
  } = useSelector(state => state.selectOptions);
  useEffect(() => {
    if (assetInfo?.sub_location?.id) {
      const { id } = assetInfo?.sub_location
      const locationName = `${assetInfo?.sub_location?.building && assetInfo?.sub_location?.building}${assetInfo?.sub_location?.level && `, ` + assetInfo?.sub_location?.level
        }${assetInfo?.sub_location?.suite && `, ` + assetInfo?.sub_location?.suite}${assetInfo?.sub_location?.room && `, ` + assetInfo?.sub_location?.room
        }`
      dispatch(selectActions.chooseLocation({ id, selected: locationName }));
      /* dispatch(selectActions.chooseSite({ id,selected:site }));
      dispatch(
        locationsPagination({ locationID: id, currentPage: 1, rowPerPage: '10', searchValue: '' }),
      ); */
    }
  }, [assetInfo])
  const changeHandler = e => {
    const valueOfInput = e.target.value;
    if (idOfSite) {
      dispatch(selectActions.searchOnLocationWithSelect({valueOfInput , subLocation}))
      dispatch(
        locationsPagination({
          locationID: idOfSite,
          currentPage: 1,
          rowPerPage: '10',
          searchValue: valueOfInput,
        }),
      );
    } else {
      dispatch(selectActions.searchOnLocationWithoutSelect({ valueOfInput, allSubLocations }));
    }
  };
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseLocation({ id, selected }));
  };
  const listItemsClick = () => {
    dispatch(selectActions.listItemsClickedLocation());
    dispatch(
      locationsPagination({
        locationID: idOfSite,
        currentPage: 1,
        rowPerPage: '10',
        searchValue: '',
      }),
    );
  };
  const onCloseSelect = () => {
    dispatch(selectActions.closeLocationSelect());
    };

  // const asset = useSelector(state => state.asset?.asset);
  let content = '--SELECT--';
  if (selectedLocation) {
    content = selectedLocation;
  } 
  // else if (selectedSite) {
  //   content = assetInfo?.sub_location?.id ===idOfSite && `${assetInfo?.sub_location?.building && assetInfo?.sub_location?.building}${
  //     assetInfo?.sub_location?.level && `, ` + assetInfo?.sub_location?.level
  //   }${assetInfo?.sub_location?.suite && `, ` + assetInfo?.sub_location?.suite}${
  //     assetInfo?.sub_location?.room && `, ` + assetInfo?.sub_location?.room
  //   }`||'--SELECT--';
  // } 
  // else if (asset?.sub_location) {
  //   content = `${asset?.sub_location?.building && asset?.sub_location?.building}${
  //     asset?.sub_location?.level && `, ` + asset?.sub_location?.level
  //   }${asset?.sub_location?.suite && `, ` + asset?.sub_location?.suite}${
  //     asset?.sub_location?.room && `, ` + asset?.sub_location?.room
  //   }`;
  // } 
  else {
    content = '--SELECT--';
  }
  return (
    <div style={{ width: width && width }} className={classes.select}>
      <div onClick={onCloseSelect} className={openLocation ? classes.overlay : ''} />
      <p>Sublocation</p>
      <div
        onClick={listItemsClick}
        className={!openLocation ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {content}
        <button
          style={noStar ? { marginRight: '10px' } : {}}
          className={openLocation ? classes.rotateIcon : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openLocation && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openLocation && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {
            valueOfLocation ? (
              subLocation?.length === 0 ? (
                <p className={classes.noResult}>NO Results Found</p>
              ) : (
                <>
                {selectedLocation  && !valueOfLocation && <li
                onClick={() => {
                  clickHandler(null, null);
                }}
                >
                  --SELECT--
                </li>
                }
               {subLocation?.map(opt => (
                  <li
                    onClick={() => {
                      clickHandler(
                        opt.id,
                        `${opt.room && opt.room}${opt.suite && `, ` + opt.suite}${opt.level && `, ` + opt.level
                        }${opt.building && `, ` + opt.building}`,
                      );
                    }}
                    key={opt.id}
                  >
                    {`${opt.room && opt.room}${opt.suite && `, ` + opt.suite}${opt.level && `, ` + opt.level
                      }${opt.building && `, ` + opt.building}`}
                  </li>
                ))}
                </>
              )
            ) : (
              <>
              {selectedLocation && !valueOfLocation && <li
              onClick={() => {
                clickHandler(null, null);
              }}
              >
                --SELECT--
              </li>
              }
              {subLocation?.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(
                      opt.id,
                      `${opt.building && opt.building}${opt.level && `, ` + opt.level}${opt.suite && `, ` + opt.suite
                      }${opt.room && `, ` + opt.room}`,
                    );
                  }}
                  key={opt.id}
                >
                  {`${opt.building && opt.building}${opt.level && `, ` + opt.level}${opt.suite && `, ` + opt.suite
                    }${opt.room && `, ` + opt.room}`}
                </li>
              ))}
              </>
            )          
          // : valueOfLocation ? (
          //   optionsLocation?.length === 0 ? (
          //     <p className={classes.noResult}>NO Results Found</p>
          //   ) : (
          //     optionsLocation?.map(opt => (
          //       <li
          //         onClick={() => {
          //           clickHandler(
          //             opt.id,
          //             `${opt.room && opt.room}${opt.suite && `, ` + opt.suite}${
          //               opt.level && `, ` + opt.level
          //             }${opt.building && `, ` + opt.building}`,
          //           );
          //         }}
          //         key={opt.id}
          //       >
          //         {`${opt.room && opt.room}${opt.suite && `, ` + opt.suite}${
          //           opt.level && `, ` + opt.level
          //         }${opt.building && `, ` + opt.building}`}
          //       </li>
          //     ))
          //   )
          // ) 
          // : (
          //   allSubLocations?.map(opt => (
          //     <li
          //       onClick={() => {
          //         clickHandler(
          //           opt.id,
          //           `${opt.building && opt.building} ${opt.level && `, ` + opt.level}${
          //             opt.suite && `, ` + opt.suite
          //           }${opt.room && `, ` + opt.room}`,
          //         );
          //       }}
          //       key={opt.id}
          //     >
          //       {`${opt.building && opt.building}${opt.level && `, ` + opt.level}${
          //         opt.suite && `, ` + opt.suite
          //       }${opt.room && `, ` + opt.room}`}
          //     </li>
          //   ))
          // )
          }
        </ul>
      )}
    </div>
  );
}

export function SelectCategory({ assetCategories }) {
  const dispatch = useDispatch();
  const asset = useSelector(state => state.asset?.asset);

  const changeHandler = e => {
    const valueOfInput = e.target.value;
    dispatch(selectActions.searchOnCategory({ valueOfInput, assetCategories }));
  };
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseCategory({ id, selected }));
  };
  const listItemsClick = () => {
    if (!asset?.from_ms) {
      dispatch(selectActions.listItemsClickedCategory());
    }
  };
  const { selectedCategory, openCategory, valueOfCategory, optionsCategory } = useSelector(
    state => state.selectOptions,
  );
  const onCloseSelect = () => {
    dispatch(selectActions.closeCategorySelect());
  };
  return (
    <div className={classes.select}>
      <div onClick={onCloseSelect} className={openCategory ? classes.overlay : ''} />
      <p>
        Category <span>*</span>
      </p>
      <div
        style={asset?.from_ms ? { backgroundColor: "#e9ecefda" } : {}}
        onClick={listItemsClick}
        className={!openCategory ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {selectedCategory ? selectedCategory : asset?.category?.name || '--SELECT--'}
        <button className={openCategory ? classes.rotateIcon : ''} type="button">
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {!asset?.from_ms && openCategory && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openCategory && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfCategory ? (
            optionsCategory.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) : (
              optionsCategory.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(opt.id, opt.name);
                  }}
                  key={opt.id}
                >
                  {opt.name}
                </li>
              ))
            )
          ) : (
            assetCategories?.map(opt => (
              <li
                onClick={() => {
                  clickHandler(opt.id, opt.name);
                }}
                key={opt.id}
              >
                {opt.name}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

export function SelectStatus({ assetStatuses }) {
  const dispatch = useDispatch();
  const asset = useSelector(state => state.asset?.asset);
  const changeHandler = e => {
    const valueOfInput = e.target.value;
    dispatch(selectActions.searchOnStatus({ valueOfInput, assetStatuses }));
  };
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseStatus({ id, selected }));
  };
  const listItemsClick = () => {
    if (!asset?.from_ms) {
      dispatch(selectActions.listItemsClickedStatus());
    }
  };
  const { selectedStatus, openStatus, valueOfStatus, optionsStatus } = useSelector(
    state => state.selectOptions,
  );
  const onCloseSelect = () => {
    dispatch(selectActions.closeStatusSelect());
  };

  return (
    <div className={classes.select}>
      <div onClick={onCloseSelect} className={openStatus ? classes.overlay : ''} />
      <p>
        Status <span>*</span>
      </p>
      <div
        style={asset?.from_ms ? { backgroundColor: "#e9ecefda" } : {}}
        onClick={listItemsClick}
        className={!openStatus ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {selectedStatus ? selectedStatus : asset?.status?.name || '--SELECT--'}
        <button className={openStatus ? classes.rotateIcon : ''} type="button">
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openStatus && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openStatus && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfStatus ? (
            optionsStatus.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) : (
              optionsStatus.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(opt.id, opt.name);
                  }}
                  key={opt.id}
                >
                  {opt.name}
                </li>
              ))
            )
          ) : (
            assetStatuses?.map(opt => (
              <li
                onClick={() => {
                  clickHandler(opt.id, opt.name);
                }}
                key={opt.id}
              >
                {opt.name}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

export function SelectAssetType({ assetTypes }) {
  const dispatch = useDispatch();
  const asset = useSelector(state => state.asset?.asset);
  const changeHandler = e => {
    const valueOfInput = e.target.value;
    dispatch(selectActions.searchOnAssetType({ valueOfInput, assetTypes }));
  };
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseAssetType({ id, selected }));
  };
  const listItemsClick = () => {
    if (!asset?.from_ms) {
      dispatch(selectActions.listItemsClickedAssetType());
    }
  };
  const { selectedAssetType, openAssetType, valueOfAssetType, optionsAssetType } = useSelector(
    state => state.selectOptions,
  );
  const onCloseSelect = () => {
    dispatch(selectActions.closeAssetTypeSelect());
  };



  return (
    <div className={classes.select}>
      <div onClick={onCloseSelect} className={openAssetType ? classes.overlay : ''} />
      <p>
        Asset Type <span>*</span>
      </p>
      <div
        style={asset?.from_ms ? { backgroundColor: "#e9ecefda" } : {}}
        onClick={listItemsClick}
        className={!openAssetType ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {selectedAssetType ? selectedAssetType : asset?.asset_type?.name || '--SELECT--'}
        <button className={openAssetType ? classes.rotateIcon : ''} type="button">
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openAssetType && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openAssetType && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfAssetType ? (
            optionsAssetType.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) : (
              optionsAssetType.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(opt.id, opt.name);
                  }}
                  key={opt.id}
                >
                  {opt.name}
                </li>
              ))
            )
          ) : (
            assetTypes?.map(opt => (
              <li
                onClick={() => {
                  clickHandler(opt.id, opt.name);
                }}
                key={opt.id}
              >
                {opt.name}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

export function SelectAssignedTo({ teams, disabled }) {
  const dispatch = useDispatch();
  const changeHandler = e => {
    const valueOfInput = e.target.value;
    dispatch(selectActions.searchOnAssignedTo({ valueOfInput, teams }));
  };
  const clickHandler = (id, selected) => {
    dispatch(selectActions.chooseAssignedTo({ id, selected }));
  };
  const listItemsClick = () => {
    if (!disabled) {
      dispatch(selectActions.listItemsClickedAssignedTo());
    } else {
      dispatch(selectActions.closeAssignedToSelect());
    }
  };
  const { assignedTo, openAssignedTo, valueOfAssignedTo, optionsAssignedTo } = useSelector(
    state => state.selectOptions,
  );
  const onCloseSelect = () => {
    dispatch(selectActions.closeAssignedToSelect());
  };
  //  const asset = useSelector(state => state.asset?.asset)
  return (
    <div className={classes.select}>
      <div onClick={onCloseSelect} className={openAssignedTo ? classes.overlay : ''} />
      <p>
        Assigned to <span>*</span>
      </p>
      <div
        style={disabled ? { backgroundColor: '#e9ecefda' } : {}}
        onClick={listItemsClick}
        className={!openAssignedTo ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {/* {selectedAssignedTo ? selectedAssignedTo : asset?.depreciation?.assigned_to[0]?.name || "--SELECT--" }  */}
        {assignedTo?.length > 0
          ? assignedTo?.map(item => (
            <p style={{ fontSize: '13px' }}>{item?.selected || item?.name}</p>
          ))
          : '--SELECT--'}
        <button className={openAssignedTo ? classes.rotateIcon : ''} type="button">
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openAssignedTo && (
        <ul className={classes.listItems}>
          <input
            autoFocus={openAssignedTo && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfAssignedTo ? (
            optionsAssignedTo.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) : (
              optionsAssignedTo.map(opt => {
                let liClass;
                const ids = assignedTo?.length > 0 ? assignedTo?.map(item => item?.id) : [];
                if (ids.includes(opt.id)) {
                  liClass = classes.chossenClass;
                }
                return (
                  <li
                    className={liClass}
                    onClick={() => {
                      clickHandler(
                        opt.id,
                        `${opt?.first_name} ${opt?.last_name} (${opt?.role === 'CliAdm' ? 'Admin' : opt?.role
                        })`,
                      );
                    }}
                    key={opt.id}
                  >
                    {(opt?.first_name || opt?.last_name) &&
                      `${opt?.first_name} ${opt?.last_name} (${opt?.role === 'CliAdm' ? 'Admin' : opt?.role
                      })`}
                  </li>
                );
              })
            )
          ) : (
            teams?.map(opt => {
              let liClass;
              const ids = assignedTo?.length > 0 ? assignedTo?.map(item => item?.id) : [];
              if (ids.includes(opt.id)) {
                liClass = classes.chossenClass;
              }
              if (opt?.first_name || opt?.last_name) {
                return (
                  <li
                    className={liClass}
                    onClick={() => {
                      clickHandler(
                        opt.id,
                        `${opt?.first_name} ${opt?.last_name} (${opt?.role === 'CliAdm' ? 'Admin' : opt?.role
                        })`,
                      );
                    }}
                    key={opt.id}
                  >
                    {`${opt?.first_name} ${opt?.last_name} (${opt?.role === 'CliAdm' ? 'Admin' : opt?.role
                      })`}
                  </li>
                );
              }
            })
          )}
        </ul>
      )}
    </div>
  );
}

export function SelectAsset({ assetData, width, setAssignedTo, woAsset = null }) {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.user)
  console.log("user", user);
  const changeHandler = e => {
    const fmValue = user?.profile?.department_name === "Facilities Management"
    const valueOfInput = e.target.value;
    // dispatch(selectActions.searchOnAsset({valueOfInput , assetData}))
    dispatch(getAssetListSearch({searchValue : valueOfInput, rowPerPage: 20,bool: fmValue}));
  };
  const clickHandler = (id, selected, assigned_to, opt) => {
    dispatch(selectActions.chooseAsset({ id, selected, opt }));
    dispatch(
      setAssignedTo(
        assigned_to?.length > 0
          ? assigned_to?.map(i => ({
            value: i?.id,
            label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
            image: i?.image,
          }))
          : [],
      ),
    );
  };
  const listItemsClick = () => {
    dispatch(selectActions.listItemsClickedAsset());
  };
  const { selectedAsset, openAsset, valueOfAsset, optionsAsset } = useSelector(
    state => state.selectOptions,
  );
  const onCloseSelect = () => {
    dispatch(selectActions.closeAssetSelect());
  };
  // const asset = useSelector(state => state.asset);
  return (
    <div style={{ width }} className={classes.select}>
      <div onClick={onCloseSelect} className={openAsset ? classes.overlay : ''} />
      <div
        onClick={listItemsClick}
        className={!openAsset ? classes.chooseItem : classes.chooseItemWithoutBorder}
      >
        {selectedAsset ? selectedAsset : woAsset && `${woAsset?.asset_number} - ${woAsset.name} ${woAsset?.location && `, ` + (woAsset?.location?.site || woAsset?.location)
          } ${woAsset?.sub_location
            ? `${woAsset?.sub_location?.room && `, ` + woAsset?.sub_location?.room}${woAsset?.sub_location?.suite && `, ` + woAsset?.sub_location?.suite
            }${woAsset?.sub_location?.level && `, ` + woAsset?.sub_location?.level}${woAsset?.sub_location?.building && `, ` + woAsset?.sub_location?.building
            }`
            : ''
          }` || '--SELECT--'}
        <button
          style={{ marginRight: '10px' }}
          className={openAsset ? classes.rotateIcon : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {openAsset && (
        <ul style={{ top: '36px' }} className={classes.listItems}>
          <input
            autoFocus={openAsset && true}
            onChange={changeHandler}
            type="text"
            placeholder="Search"
          />
          {valueOfAsset ? (
            optionsAsset.length === 0 ? (
              <p className={classes.noResult}>NO Results Found</p>
            ) : (
              <>
              {selectedAsset  && !valueOfAsset && <li
              onClick={() => {
                clickHandler(null, null , [] , null);
              }}
              >
                --SELECT--
              </li>
              }
              {optionsAsset.map(opt => (
                <li
                  onClick={() => {
                    clickHandler(
                      opt.id,
                      `${opt.asset_number} - ${opt.name} ${opt?.location && `, ` + opt?.location?.site?.site
                      } ${opt.sub_location
                        ? `${opt?.sub_location?.room && `, ` + opt?.sub_location?.room}${opt?.sub_location?.suite && `, ` + opt?.sub_location?.suite
                        }${opt?.sub_location?.level && `, ` + opt?.sub_location?.level}${opt?.sub_location?.building && `, ` + opt?.sub_location?.building
                        }`
                        : ''
                      }`,
                      opt?.assigned_to,
                      opt?.id
                    );
                  }}
                  key={opt.id}
                >
                  {`${opt.asset_number} - ${opt.name} ${opt?.location && `, ` + opt?.location?.site
                    } ${opt.sub_location
                      ? `${opt?.sub_location?.room && `, ` + opt?.sub_location?.room}${opt?.sub_location?.suite && `, ` + opt?.sub_location?.suite
                      }${opt?.sub_location?.level && `, ` + opt?.sub_location?.level}${opt?.sub_location?.building && `, ` + opt?.sub_location?.building
                      }`
                      : ''
                    }`}
                </li>
              ))}
              </>
            )
          ) : assetData?.length === 0 ? (
            <p className={classes.noResult}>NO Results Found</p>
          ) : (
             <>
              {selectedAsset  && !valueOfAsset && <li
              onClick={() => {
                clickHandler(null, null , [] , null);
              }}
              >
                --SELECT--
              </li>
              }
            {assetData?.slice(0, 20)?.map(opt => {
              return (
                <div key={opt.id}>
                  <li
                    onClick={() => {
                      clickHandler(
                        opt.id,
                        `${opt.asset_number} - ${opt.name} ${opt?.location && `, ` + opt?.location?.site
                        } ${opt.sub_location
                          ? `${opt?.sub_location?.room && `, ` + opt?.sub_location?.room}${opt?.sub_location?.suite && `, ` + opt?.sub_location?.suite
                          }${opt?.sub_location?.level && `, ` + opt?.sub_location?.level}${opt?.sub_location?.building && `, ` + opt?.sub_location?.building
                          }`
                          : ''
                        }`,
                        opt?.assigned_to,
                        opt
                      );
                    }}
                  >
                    {`${opt.asset_number} - ${opt.name} ${opt?.location && `, ` + opt?.location?.site
                      } ${opt.sub_location
                        ? `${opt?.sub_location?.room && `, ` + opt?.sub_location?.room}${opt?.sub_location?.suite && `, ` + opt?.sub_location?.suite
                        }${opt?.sub_location?.level && `, ` + opt?.sub_location?.level}${opt?.sub_location?.building && `, ` + opt?.sub_location?.building
                        }`
                        : ''
                      } `}
                  </li>
                  {opt?.children.length > 0 &&
                    opt.children.map(o => {
                      return (
                        <div key={o.id}>
                          <li
                            onClick={() => {
                              clickHandler(
                                o.id,
                                `${o.asset_number} - ${o.name} ${o?.location && `, ` + o?.location?.site
                                } ${o.sub_location
                                  ? `${o?.sub_location?.room && `, ` + o?.sub_location?.room}${o?.sub_location?.suite && `, ` + o?.sub_location?.suite
                                  }${o?.sub_location?.level && `, ` + o?.sub_location?.level}${o?.sub_location?.building &&
                                  `, ` + o?.sub_location?.building
                                  }`
                                  : ''
                                }`,
                                o?.assigned_to,
                                o
                              );
                            }}
                          >
                            {`${o.asset_number} - ${o.name} ${o?.location && `, ` + o?.location?.site
                              } ${o.sub_location
                                ? `${o?.sub_location?.room && `, ` + o?.sub_location?.room}${o?.sub_location?.suite && `, ` + o?.sub_location?.suite
                                }${o?.sub_location?.level && `, ` + o?.sub_location?.level}${o?.sub_location?.building && `, ` + o?.sub_location?.building
                                }`
                                : ''
                              }`}
                          </li>
                          {o?.children.length > 0 &&
                            o.children.map(op => {
                              return (
                                <div key={op.id}>
                                  <li
                                    onClick={() => {
                                      clickHandler(
                                        op.id,
                                        `${op?.asset_number} - ${op?.name} ${op?.location && `, ` + op?.location?.site
                                        } ${o.sub_location
                                          ? `${op?.sub_location?.room &&
                                          `, ` + op?.sub_location?.room
                                          }${op?.sub_location?.suite &&
                                          `, ` + op?.sub_location?.suite
                                          }${op?.sub_location?.level &&
                                          `, ` + op?.sub_location?.level
                                          }${op?.sub_location?.building &&
                                          `, ` + op?.sub_location?.building
                                          }`
                                          : ''
                                        }`,
                                        op?.assigned_to,
                                        op
                                      );
                                    }}
                                  >
                                    {`${op?.asset_number} - ${op?.name} ${op?.location && `, ` + op?.location?.site
                                      } ${op.sub_location
                                        ? `${op?.sub_location?.room && `, ` + op?.sub_location?.room
                                        }${op?.sub_location?.suite &&
                                        `, ` + op?.sub_location?.suite
                                        }${op?.sub_location?.level &&
                                        `, ` + op?.sub_location?.level
                                        }${op?.sub_location?.building &&
                                        `, ` + op?.sub_location?.building
                                        }`
                                        : ''
                                      }`}
                                  </li>
                                  {op?.children.length > 0 &&
                                    op.children.map(ot => {
                                      return (
                                        <div key={ot.id}>
                                          <li
                                            onClick={() => {
                                              clickHandler(
                                                ot.id,
                                                `${ot?.asset_number} - ${ot?.name} ${ot?.location && `, ` + ot?.location?.site
                                                } ${ot.sub_location
                                                  ? `${ot?.sub_location?.room &&
                                                  `, ` + ot?.sub_location?.room
                                                  }${ot?.sub_location?.suite &&
                                                  `, ` + ot?.sub_location?.suite
                                                  }${ot?.sub_location?.level &&
                                                  `, ` + ot?.sub_location?.level
                                                  }${ot?.sub_location?.building &&
                                                  `, ` + ot?.sub_location?.building
                                                  }`
                                                  : ''
                                                }`,
                                                ot?.assigned_to,
                                                ot
                                              );
                                            }}
                                          >
                                            {`${ot?.asset_number} - ${ot?.name} ${ot?.location && `, ` + ot?.location?.site
                                              } ${ot.sub_location
                                                ? `${ot?.sub_location?.room &&
                                                `, ` + ot?.sub_location?.room
                                                }${ot?.sub_location?.suite &&
                                                `, ` + ot?.sub_location?.suite
                                                }${ot?.sub_location?.level &&
                                                `, ` + ot?.sub_location?.level
                                                }${ot?.sub_location?.building &&
                                                `, ` + ot?.sub_location?.building
                                                }`
                                                : ''
                                              }`}
                                          </li>
                                          {ot?.children.length > 0 &&
                                            ot.children.map(oc => {
                                              return (
                                                <div key={oc.id}>
                                                  <li
                                                    onClick={() => {
                                                      clickHandler(
                                                        oc.id,
                                                        `${oc?.asset_number} - ${oc?.name} ${oc?.location && `, ` + oc?.location?.site
                                                        } ${oc.sub_location
                                                          ? `${oc?.sub_location?.room &&
                                                          `, ` + oc?.sub_location?.room
                                                          }${oc?.sub_location?.suite &&
                                                          `, ` + oc?.sub_location?.suite
                                                          }${oc?.sub_location?.level &&
                                                          `, ` + oc?.sub_location?.level
                                                          }${oc?.sub_location?.building &&
                                                          `, ` + oc?.sub_location?.building
                                                          }`
                                                          : ''
                                                        }`,
                                                        oc?.assigned_to,
                                                        oc
                                                      );
                                                    }}
                                                  >
                                                    {`${oc?.asset_number} - ${oc?.name} ${oc?.location && `, ` + oc?.location?.site
                                                      } ${ot.sub_location
                                                        ? `${oc?.sub_location?.room &&
                                                        `, ` + oc?.sub_location?.room
                                                        }${oc?.sub_location?.suite &&
                                                        `, ` + oc?.sub_location?.suite
                                                        }${oc?.sub_location?.level &&
                                                        `, ` + oc?.sub_location?.level
                                                        }${oc?.sub_location?.building &&
                                                        `, ` + oc?.sub_location?.building
                                                        }`
                                                        : ''
                                                      }`}
                                                  </li>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              );
              })}
            </>
          )}
        </ul>
      )}
    </div>
  );
}

// export function SelectDepartment({departments , slectedDepartment}) {

//   // const onCloseSelect = () => {
//   //   dispatch(selectActions.closeShareableAssetSelect())
//   // }

//   const selectDepartmentAction = (value) => {
//     dispatch(selectActions.setAssetDepartment(value))
//   };
//   return (
//     <div >
//       <div onClick={onCloseSelect} className={openIsShareable ? classes.overlay : ""} />
//       <div onClick={listItemsClick}
//         className={!openIsShareable ? classes.chooseItem :
//           classes.chooseItemWithoutBorder}>
//         <select
//           onChange={(e) => {
//             selectDepartmentAction(e.target.value)
//           }}
//           value={slectedDepartment?.id}
//         >
//           <option value="" disabled>--Select--</option>
//           {departments?.map((singleDepartment)=>(
//             <option key={departments.id} value={singleDepartment.id}>{singleDepartment.name}</option>
//           ))}
//         </select>
//   )
// }

export function SelectPersons({ assignedTo, setAssignedTo, teams, customForLogTime, stopped }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const modifiedDataFun = (array, data) => {
    if (user?.role === 'Requester') {
      data.filter(item => item.role === 'CliAdm').forEach(m => array.push({ ...m, role: 'Admin' }));
      return;
    }
    data?.forEach(item => {
      if (item?.role === 'CliAdm') {
        array.push({ ...item, role: 'Admin' });
      } else array.push(item);
    });
  };

  const teamData =
    teams && teams.length > 0 ? teams?.filter(team => team?.role !== 'Requester' && team?.status !== false) : [];
  const modifiedTeam = [];
  modifiedDataFun(modifiedTeam, teamData);

  const options = modifiedTeam?.map(i => ({
    value: i?.id,
    label: `${i?.first_name} ${i?.last_name} (${i?.role})`,
    image: i?.profile?.image,
  }));

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={image} alt={label} style={{ width: '32px', height: '32px', marginRight: '8px' }} />
      <span>{label}</span>
    </div>
  );

  const getTeamData = res => {
    const response = res.payload.results?.filter(team => team?.role !== 'Requester' && team?.status !== false);
    const modifiedData = [];
    modifiedDataFun(modifiedData, response);

    return modifiedData?.map(i => ({
      value: i?.id,
      label: `${i?.first_name} ${i?.last_name} (${i?.role})`,
      image: i?.profile?.image,
    }));
  };

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      const res = await dispatch(getTeamsByAuthentication());
      return getTeamData(res);
    }
    const res = await dispatch(
      teamsPagination({ rowPerPage: 20, currentPage: 1, searchValue: inputValue }),
    );
    return getTeamData(res);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs

  const handelAssignedTo = e => {
    dispatch(setAssignedTo(e));
  };

  return (
    <AsyncSelect
      isMulti={!customForLogTime}
      isDisabled={stopped}
      value={assignedTo}
      defaultOptions={options}
      loadOptions={promiseOptions}
      onChange={handelAssignedTo}
      formatOptionLabel={formatOptionLabel}
      onInputChange={handleInputChange} // Handle input value changes with debounce
    />
  );
}

export function SelectTemplate({ checklists, template, setTemplate }) {
  const dispatch = useDispatch();
  function covertData(checklist) {
    return checklist?.length > 0
      ? checklist?.map(i => ({
        value: i?.id,
        label: i?.title,
        ...i,
      }))
      : [];
  }

  const options = covertData(checklists);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      const res = await dispatch(getChecklistByAuthentication());
      return covertData(res.payload.results);
    }
    // const data = []
    // checklists.length > 0 && checklists.map((e) => {
    //   if (e?.title.toLowerCase()?.includes(inputValue?.toLowerCase())) {
    //     data.push(e)
    //   }
    // })
    const res = await dispatch(
      checklistPagination({ rowPerPage: 10, currentPage: 1, searchValue: inputValue }),
    );
    return covertData(res.payload.results);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };
  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs

  return (
    <AsyncSelect
      isMulti
      options={checklists}
      defaultOptions={options}
      onChange={e => setTemplate(e)}
      formatOptionLabel={formatOptionLabel}
      value={template}
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
    />
  );
}


export function SelectMeter ({ meters, meterId, setMeterId, setAssignedTo, locations }) {
  const dispatch = useDispatch();
  function covertData (meter) {
    return meter?.length > 0
      ? meter?.map(i => ({
          value: i?.id,
          label: i?.title,
          ...i,
        }))
      : [];
  }

  const options = covertData(meters);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      const res = await dispatch(getMetersByPagination({ rowPerPage: 20, currentPage: 1, SearchVal: "" }));
      return covertData(res.payload.results);
    }
    const res = await dispatch(
      getMetersByPagination({ rowPerPage: 20, currentPage: 1, SearchVal: inputValue }),
    );
    return covertData(res.payload.results);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  const handelChooseMeter=(e)=>{
    dispatch(setMeterId(e))
    if (e?.assigned_to?.length > 0) {
      dispatch(setAssignedTo( 
        e?.assigned_to?.map(i => ({
              value: i?.id,
              label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
              image: i?.image,
            }))
      )
    );
    }

    if(e?.location?.id){
      const {id,site} = e?.location 
      dispatch(selectActions.chooseSite({ id,selected:site }));
      dispatch(
        locationsPagination({ locationID: id, currentPage: 1, rowPerPage: '10', searchValue: '' }),
      );
      dispatch(selectActions.chooseAsset({ id: null,selected:"" }));
    }
    if(e?.sub_location?.id){
      const {id} = e?.sub_location
      const locationName =   e?.sub_location ?`${e?.sub_location?.building && e?.sub_location?.building}${
        e?.sub_location?.level && `, ` + e?.sub_location?.level
      }${e?.sub_location?.suite && `, ` + e?.sub_location?.suite}${
        e?.sub_location?.room && `, ` + e?.sub_location?.room
      }` : ""
      dispatch(selectActions.chooseLocation({ id, selected:locationName }));
    }
    if(e?.asset?.id){
      const asset = e?.asset 
      const subLocation = `${
        asset.sub_location
          ? `${asset?.sub_location?.room && `, ` + asset?.sub_location?.room}${
              asset?.sub_location?.suite && `, ` + asset?.sub_location?.suite
            }${asset?.sub_location?.level && `, ` + asset?.sub_location?.level}${
              asset?.sub_location?.building && `, ` + asset?.sub_location?.building
            }`
          : ''
      }`

      const selected =`${asset.asset_number} - ${asset.name} ${
        asset?.location && `, ` + asset?.location
      } ${subLocation}`
      const locationData = locations?.find(loc => asset?.location === loc?.site)


      dispatch(selectActions.chooseAsset({ id: asset?.id,selected }));
      dispatch(selectActions.chooseSite({ id:locationData?.id, selected: asset?.location }));
      dispatch(selectActions.chooseLocation({ id:asset?.sub_location?.id, selected: subLocation }));
    }
  }

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs
  return (
    <AsyncSelect
      isMulti={false}
      options={meters}
      defaultOptions={options}
      onChange={e => handelChooseMeter(e)}
      formatOptionLabel={formatOptionLabel}
      value={meterId}
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
    />
  );
}


export function SelectOptions ({ reusableData, handelChange, locations, stateValue }) {
  function covertData (mapping) {
    return mapping?.length > 0
      ? mapping?.slice(0, 5)?.map(i => ({
        value: i?.id,
        label: !locations
          ? i?.name
          : `${i?.room && i?.room}${i?.suite && `, ` + i?.suite}${i?.level && `, ` + i?.level}${i?.building && `, ` + i?.building
          }`,
        ...i,
      }))
      : [];
  }

  const options = covertData(reusableData);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      return;
    }
    const data = [];
    reusableData.length > 0 &&
      reusableData.map(e => {
        if (!locations) {
          if (e?.name.toLowerCase()?.includes(inputValue?.toLowerCase())) {
            data.push(e);
          }
        } else if (locations) {
          if (e?.room.toLowerCase()?.includes(inputValue?.toLowerCase())) {
            data.push(e);
          }
        }
      });
    return covertData(data);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs
  return (
    <AsyncSelect
      isMulti={false}
      options={reusableData}
      defaultOptions={options}
      onChange={e => handelChange(e)}
      formatOptionLabel={formatOptionLabel}
      value={stateValue}
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
    />
  );
}

export function SelectTypesAndCategories({ dataArray, stateValue, setState }) {
  const dispatch = useDispatch();
  function covertData(type) {
    return type?.length > 0
      ? type?.map(i => ({
        value: i?.id,
        label: i?.name,
      }))
      : [];
  }

  // Create options with "All" as the first option
  const options = [{ value: '', label: 'All' }, ...covertData(dataArray)];

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      return;
    }
    const data = [];
    dataArray.length > 0 &&
      dataArray.map(e => {
        if (e?.name.toLowerCase()?.includes(inputValue?.toLowerCase())) {
          data.push(e);
        }
      });
    return covertData(data);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs
  return (
    <AsyncSelect
      isMulti={false}
      options={dataArray}
      defaultOptions={options}
      onChange={e => dispatch(setState(e))}
      formatOptionLabel={formatOptionLabel}
      value={stateValue || options[0]} // Set default value to "All"
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
    />
  );
}

export function SelectLocationFilter({
  locations,
  site,
  setFilterSite,
  setLocations,
  setFilterSublocation,
  validator,
  validatorComp,
  setLocation
}) {
  const dispatch = useDispatch();

  // Function to convert data for options
  function covertData(location) {
    return location?.length > 0
      ? location?.map(i => ({
        value: i?.id,
        label: i?.site,
      }))
      : [];
  }

  // Create options with "All" as the first option
  const options = validator ? [...covertData(locations)] : [{ value: '', label: 'All' }, ...covertData(locations)];

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    console.log("inputValue", inputValue);
    if (!inputValue) {
      const res = await dispatch(getLocationsByAuthentication());
      return covertData(res.payload.results);
    }
    if (inputValue) {
      const res = await dispatch(
        getLocationByPagination({ rowPerPage: 20, currentPage: 1, searchValue: inputValue }),
      );
      return covertData(res.payload.results);
    }

  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs

  return (
    <AsyncSelect
      isMulti={false}
      options={options}
      defaultOptions={options}
      onChange={e => {
        if (validatorComp && validator) {
          console.log("validatorComp");
          setLocation(e)
        }
        else if (validator && !validatorComp) {
          dispatch(setFilterSite(e));
          console.log("!else");
        }
        else {
          setLocations([]);
          dispatch(setFilterSublocation(null));
          dispatch(setFilterSite(e));
        } 
      }}
      formatOptionLabel={formatOptionLabel}
      value={!validator ? site || options[0] : site} // Set default value to "All"
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
      styles={{
        borderColor: 'red', // Set border color to red
      }}
    />
  );
}

export function SelectSublocationFilter({
  sublocationsData,
  sublocation,
  setFilterSublocation,
  site,
}) {
  const dispatch = useDispatch();

  // Function to convert data for options
  function covertData(location) {
    return location?.length > 0
      ? location?.map(i => ({
        value: i?.id,
        label: i?.room,
        ...i,
      }))
      : [];
  }

  // Create options with "All" as the first option
  const options = [{ value: '', label: 'All' }, ...covertData(sublocationsData)];

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (site?.value) {
      if (!inputValue) {
        const res = await dispatch(
          locationsPagination({
            locationID: site?.value,
            currentPage: 1,
            rowPerPage: '20',
            searchValue: '',
          }),
        );
        return [{ value: '', label: 'All' }, ...covertData(res.payload.results)];
      }
      const res = await dispatch(
        locationsPagination({
          locationID: site?.value,
          currentPage: 1,
          rowPerPage: '20',
          searchValue: inputValue,
        }),
      );
      return [{ value: '', label: 'All' }, ...covertData(res.payload.results)];
    }

    return covertData([]);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs

  return (
    <AsyncSelect
      isMulti={false}
      options={options}
      defaultOptions={options}
      onChange={e => dispatch(setFilterSublocation(e))}
      formatOptionLabel={formatOptionLabel}
      value={sublocation || options[0]} // Set default value to "All"
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
      styles={{
        borderColor: 'red', // Set border color to red
      }}
    />
  );
}

export function SelectStaff({ teams, staff, setStaff, validator, validatorComp }) {
  const dispatch = useDispatch();

  // Function to convert data for options
  function covertData(team) {
    return team?.length > 0
      ? team?.map(i => ({
        value: i?.id,
        label: `${i?.first_name} ${i?.last_name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
      }))
      : [];
  }
  // Create options with "All" as the first option
  const options = validator ? covertData(teams) : [{ value: '', label: 'All staff' }, ...covertData(teams)];

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      const res = await dispatch(getTeamsByAuthentication());
      return [{ value: '', label: 'All' }, ...covertData(res.payload.results)];
    }
    const res = await dispatch(
      teamsPagination({ rowPerPage: 20, currentPage: 1, searchValue: inputValue }),
    );
    return [{ value: '', label: 'All' }, ...covertData(res.payload.results)];
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs


  return (
    <AsyncSelect
      isMulti={false}
      options={options}
      defaultOptions={options}
      onChange={e => {
        if (validatorComp) {
          setStaff(e)
        } else {
          dispatch(setStaff(e))
        }

      }}
      formatOptionLabel={formatOptionLabel}
      value={!validator ? staff || options[0] : staff} // Set default value to "All"
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
      styles={{
        borderColor: 'red', // Set border color to red
      }}
    />
  );
}

export function SelectDateFilter({ dateOptions, filter, handleDate }) {
  // Function to convert data for options
  function covertData(team) {
    return team?.length > 0
      ? team?.map(i => ({
        value: i?.value,
        label: i?.label,
      }))
      : [];
  }
  // Create options with "All" as the first option
  const options = covertData(dateOptions);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  return (
    <AsyncSelect
      isMulti={false}
      options={options}
      defaultOptions={options}
      onChange={e => handleDate(e)}
      formatOptionLabel={formatOptionLabel}
      value={options.find(option => option.value === filter?.value) || options[0]} // Set default value to "All"
      styles={{
        borderColor: 'red', // Set border color to red
      }}
    />
  );
}



export function SelectResponsibleEmployee({ responsibleEmployees, setResponsibleEmployee, responsibleEmployee }) {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.user)
  function covertData(mapping) {
    return mapping?.length > 0
      ? mapping?.slice(0, 5)?.map(i => ({
        value: i?.id,
        label: i?.SearchName,
      }))
      : [];
  }

  const options = covertData(responsibleEmployees);

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
    </div>
  );

  const promiseOptions = async inputValue => {
    if (!inputValue) {
      return;
    }
    const data = [];
    responsibleEmployees.length > 0 &&
      responsibleEmployees.map(e => {
        if (e?.SearchName.toLowerCase()?.includes(inputValue?.toLowerCase())) {
          data.push(e);
        }
      });
    return covertData(data);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };
  // Function to handle input value changes and call loadOptions
  const handleInputChange = debounce(newValue => {
    promiseOptions(newValue);
  }, 500); // Adjust the delay (in milliseconds) to suit your needs
  return (
    <AsyncSelect   
      isDisabled={user?.profile?.department_name !== "General Administration"}
      isMulti={false}
      value={responsibleEmployee}
      options={responsibleEmployees}
      defaultOptions={options}
      onChange={e => dispatch(setResponsibleEmployee(e))}
      formatOptionLabel={formatOptionLabel}
      onInputChange={handleInputChange} // Handle input value changes with debounce
      loadOptions={promiseOptions}
    />
  );
}
