

/* eslint-disable import/newline-after-import */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-else-return */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";
import { faFilter, faPlus, faEllipsis, faDownload } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from "file-saver";
import classes from "../../assetsPage/assestsPage.module.css"
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { openWorkOrderModalCreate, resetWorkOrder, setWorkOrder, setSearchValue, setTab, reset } from '../../../redux/features/workOrder/workOrderSlice';
import { setAsset } from '../../../redux/features/asset/assetSlice';
import PopUpAddModal from '../../../components/popUpModals/editCategory/popUpAddModal';
import { BASE_URL } from '../../../utils/axios';

/* eslint-disable prefer-template */

function WOHeader({ setISShowFilter,
  iSShowFilter }) {
  const dispatch = useDispatch()
  const { searchValue } = useSelector(state => state.workOrder)
  const [iSShowImports, setISShowImports] = useState(false)
  const [iSShowExportPopup, setISShowExportPopup] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedOption, setSelectedOption] = useState("All");
  
  const fileExtension = ".csv";
  const fileExName = "exported_workOrders_data"

  const handelChange = (e) => {
    dispatch(setSearchValue(e.target.value))
  }

  const exportToCSV = async (apiData, fileName) => {
    const headers = Object.keys(apiData[0]);

    // Convert data to CSV format
    const csvContent = apiData.map(row => {
      return headers.map(header => {
        // If the field contains a comma or double quote, enclose it in quotes and escape any double quotes
        let field = row[header].replace(/"/g, '""'); // Escape double quotes
        if (field.includes(',') || field.includes('"') || field.includes('\n')){
          field = `"${field}"`; // Enclose in quotes if necessary
        }
        return field;
      }).join(',');
    }).join('\n');

    // Construct the final CSV content
    const finalCSV = headers.join(',') + '\n' + csvContent;

    // Create a Blob from the CSV content with the appropriate Content-Type
    const data = new Blob([finalCSV], { type: 'text/csv' });

    // Save the Blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };


  const handelReset = () => {
    dispatch(setWorkOrder(null))
    dispatch(resetWorkOrder())
    dispatch(setAsset(null))
    dispatch(selectActions.reset())
    dispatch(setTab("maintenance-tab"))
    dispatch(openWorkOrderModalCreate())
  }

  const handleClosePopup = () => {
    setISShowExportPopup(false)
    // setStartDate('')
    // setEndDate('')
  }

  const arrayOfFields = [
    { field: 'ticket_number', label: "Ticket Number", value: true },
    { field: 'title', label: "Title", value: true },
    { field: 'asset', label: "Asset", value: true },
    { field: 'site', label: "Location", value: true },
    { field: 'room', label: "Room", value: true },
    { field: 'suite', label: "Suite", value: true },
    { field: 'level', label: "Level", value: true },
    { field: 'building', label: "Building", value: true },
    { field: 'inventory', label: "Inventory", value: true },
    { field: 'category', label: "Category", value: true },
    { field: 'cust_type', label: "Sub Category", value: true },
    { field: 'assigned_to', label: "Assigned To", value: true },
    { field: 'type', label: "Type", value: true },
    { field: 'priority', label: "Priority", value: true },
    { field: 'status', label: "Status", value: true },
    { field: 'due_finish', label: "Due Finish", value: true },
    { field: 'completed_date', label: "Completed Date", value: true },
    { field: 'start_date', label: "Start Date", value: true },
    { field: 'created_at', label: "Created At", value: true },
    { field: 'modified_at', label: "Modified At", value: true },
    { field: 'description', label: "Description", value: true },
    { field: 'comment', label: "Comment", value: true },
  ]

  const [fields, setFields] = useState(arrayOfFields)

  const handelFieldsChange = (index) => {
    const data = fields.map((item, i) => {
      if (index === i) {
        return { ...item, value: !item.value }
      }
      return item
    })
    setFields(data)
  }
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "All") {
      const data = fields.map((item) => {
        return { ...item, value: true }
      })
      setFields(data)
    }
    else {
      const data = fields.map((item) => {
        return { ...item, value: false }
      })
      setFields(data)
    }
  };

  const handleExport = async () => {
    const token = `JWT ${JSON.parse(localStorage.getItem('userToken'))?.access}`
    const fieldsArrayOfObj = fields.filter(item => item.value === true)
    const fieldsArray = fieldsArrayOfObj.map(item => item.field)
    if (!endDate && !startDate) {
      toast.warning("Please fill Start and End Date fields", {
        toastId: "warning",
      });
      return;
    }
    else if (!startDate) {
      toast.warning("Please fill Start Date field", {
        toastId: "warning",
      });
      return;
    }
    else if (!endDate) {
      toast.warning("Please fill End Date field", {
        toastId: "warning",
      });
      return;
    }
    try {
      if (new Date(endDate) <= new Date(startDate)) {
        throw new Error('End date must be greater than the start date.');
      }
      if ((new Date(endDate).getTime() - new Date(startDate).getTime()) > 365 * 24 * 60 * 60 * 1000) {
        throw new Error('Time span between start date and end date should not be more than a year.');
      }
      const response = await fetch(`${BASE_URL}/workorder-export/?start_date=${startDate}&end_date=${endDate}${selectedOption === "Custom" ? `&fields=${fieldsArray.join(",")}` : "" }`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? token : null
        },
        body: JSON.stringify({ extension: "JSON" }),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.details);
      }

      const result = await response.json();
      if (result?.length > 0) {
        exportToCSV(result, fileExName);
        dispatch(reset());
      }

      // Process the result
      toast.success("Work Orders Exported successfully", {
        toastId: "success",
      });
      handleClosePopup()

    } catch (error) {
      toast.error(error.message, {
        toastId: "error",
      });
    }
  }

  return (
    <>
      <div className={classes.assetsTop}>
        <h4 className={classes.assetsTitle}>Work Order</h4>
        <div className={classes.assetActions}>
          <input type="text" placeholder='Search' value={searchValue} onChange={handelChange} />
          <button
            onClick={handelReset}
            type='button'
            className={classes.assetfaPlus}>
            <span><FontAwesomeIcon icon={faPlus} /></span>
          </button>
          <button type='button' onClick={() => setISShowFilter(!iSShowFilter)} className={classes.assetFilter}>
            <FontAwesomeIcon icon={faFilter} />
          </button>
          <button type='button' onClick={() => setISShowImports(!iSShowImports)} className={classes.assetEllipsis}>
            <FontAwesomeIcon icon={faEllipsis} />
          </button>

        </div>
      </div>
      {
        iSShowImports && <div className={classes.imports}>
            <div onClick={() => setISShowExportPopup(true)}>
              <FontAwesomeIcon icon={faDownload} />
              Export To Excel
            </div>
        </div>
      }
      {iSShowExportPopup && (
        <PopUpAddModal
          showModal={iSShowExportPopup}
          handleCreateSave={handleExport}
          handleClose={handleClosePopup}
          modalTitle="Export Work Order"
          primaryButtonText="Export"
        >
          <>
            <h5 className='mb-2' style={{ fontSize: "18px" }}>Work Order Exported by Specified Period </h5>
            <div className="row">
              <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  Start Date<label className="font-danger m-0">*</label>
                </label>
                <input
                  type="date"
                  className="form-control mb-2"
                  name="service_date"
                  id="meter_service_date"
                  placeholder="Start Date"
                  required
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  End Date
                  <label className="font-danger m-0">*</label>
                </label>
                <input
                  type="date"
                  className="form-control mb-2"
                  name="service_date"
                  id="PM_service_date"
                  placeholder="End Date"
                  required
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <h5 className='mb-2 mt-2' style={{ fontSize: "18px" }}>Choose Columns</h5>
            <form>
              <div style={{ display: "flex", alignItems: "center", marginTop: "20px", border: selectedOption === "All"?  "2px solid #0075ff" : "2px solid #ccc" , padding: "10px" , borderRadius: "10px" }}>
                <input type="radio"
                  id="All"
                  value="All"
                  checked={selectedOption === 'All'}
                  onChange={handleOptionChange} />
                <div className='ml-3' >
                  <h6 className='mb-1'>All Columns</h6>
                  <span className='text-gray'> All of your work orders will be exported</span>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: "20px" , border: selectedOption === "Custom"?  "2px solid #0075ff" : "2px solid #ccc" , padding: "10px" , borderRadius: "10px" }}>
                <input type="radio"
                  id="Custom"
                  value="Custom"
                  checked={selectedOption === 'Custom'}
                  onChange={handleOptionChange} />
                <div className='ml-3'>
                  <h6 className='mb-1'>Custom</h6>
                  <span className='text-gray'>Only selected columns will be exported</span>
                </div>
              </div>
            </form>
            <div className="row mt-4">
                {fields?.map((item, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6" style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
                    <input
                      id={item.label}
                      type="checkbox"
                      className='checkbox-column'
                      checked={item.value}
                      disabled={selectedOption === "All"}
                      onChange={() => handelFieldsChange(index)}
                    />
                    <label htmlFor={item.label} className="font-weight-normal ml-2 mb-0">
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
          </>
        </PopUpAddModal>
      )}
    </>
  )
}

export default WOHeader




