import React from 'react'
import { useSelector } from 'react-redux';
import moment from "moment";
import Card from "../../../Ui/Card"
import classes from "../addAsset/addAssetForm/assetForm.module.css"
// import { SelectAssignedTo } from '../../../components/select/select';
import DeprictionChart from './DeprictionChart';
import { setAssetAssignedTo } from '../../../redux/features/asset/assetSlice';
import { SelectPersons } from '../../../components/select/select';

/* eslint-disable no-nested-ternary */

function FetchAssetDepricationForm(
  { handelEditDepricationChange,
    handelChangeDepricationForm,
    enableDeprication,
    assetDepricationForm,
    handelFilter,
    chartData,
    selectedOption,
    assetDepreciation,
    handelViewAndEdit,
    viewAndEdit,
    assetFinanceForm,
  }) {
  const teams = useSelector(state => state.teams?.teams?.results)
  const { assetAssignedTo, asset } = useSelector(state => state.asset)

  return (
    <div className={classes.assetForm}>
      {
        (assetDepreciation?.end_service_life ||
          assetDepreciation?.depreciation_rate_year ||
          assetDepreciation?.depreciation_rate_month ||
          assetDepreciation?.depreciation_rate_week) && enableDeprication &&
        <Card myClass={classes.cardOfChart} >
          <div className="card col-lg-12 col-sm-12 mb-0" style={{ textAlign: "center" }}>
            <span ><b className="p-2">VIEW</b>
              <input type="checkbox" className="switch" checked={!viewAndEdit} id="view_edit_depreciation" onChange={handelViewAndEdit} />
              <b className="p-2">EDIT</b></span>
          </div>
        </Card>

      }
      {(assetDepreciation?.end_service_life ||
        assetDepreciation?.depreciation_rate_year ||
        assetDepreciation?.depreciation_rate_month ||
        assetDepreciation?.depreciation_rate_week) && enableDeprication &&
        viewAndEdit &&
        <Card myClass={classes.cardOfChart}>
          <div className="card col-lg-12 col-sm-12" id="asset_depreciation_graph">
            <div className="card-header medium-title">
              <span ><b>Asset Depreciation</b></span>
            </div>
            <div className="col-12">
              <div className={`card ${classes.cardWraper}`}>
                <div className="card-header p-0">
                  <div className="row col-lg-12 m-0 p-0">
                    <div className={`info-box elevation-0  mb-0 col-4 tab ${classes.depTab}`} id="purchased_price">
                      <div className="info-box-content text-center">
                        <span className="info-box-text"> Purchase Price</span>
                        <span className="info-box-number">
                          RM {asset ? asset?.finance?.purchased_price : assetFinanceForm.purchased_price}
                        </span>
                      </div>
                    </div>
                    <div className={`info-box elevation-0  mb-0 col-4 tab ${classes.depTab}`} id="salvage_price">
                      <div className="info-box-content text-center">
                        <span className="info-box-text">Salvage Price</span>
                        <span className="info-box-number">
                          RM {asset ? asset?.depreciation?.salvage_price : assetDepricationForm?.salvage_price}
                        </span>
                      </div>
                    </div>
                    <div className={`info-box elevation-0  mb-0 col-4 tab ${classes.depTab}`} id="service_life">
                      <div className="info-box-content text-center">
                        <span className="info-box-text">Service Life</span>
                        <span className="info-box-number">
                          {` ${asset ? asset?.depreciation?.service_life : assetDepricationForm?.service_life} 
                                  ${asset ? asset?.depreciation?.filter : assetDepricationForm?.filter}s`}
                        </span>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="card-body">

                  <div className="row mb-4">
                    <div className="col-6 text-center">
                      <span className="mr-2"> End of service life</span>
                      <span className=" font-weight-bold">

                        {moment(assetDepreciation?.end_service_life).format('MMM Do YYYY')}

                      </span>
                    </div>
                    <div className="col-6 text-center">
                      <span className="mr-2">Depreciation rate </span>
                      <span className="font-weight-bold" id="depreciation_rate">
                        RM {selectedOption === "year" ? assetDepreciation?.depreciation_rate_year : selectedOption === "month" ?
                          assetDepreciation?.depreciation_rate_month : assetDepreciation?.depreciation_rate_week}
                      </span>
                      <select
                        className={classes.filterSelect}
                        id="filter"
                        value={selectedOption}
                        onChange={handelFilter}
                      >
                        <option value="" disabled selected>--SELECT--</option>
                        {assetDepreciation?.filter_data?.year && <option value="year">{assetDepreciation?.filter_data?.year}</option>}
                        {assetDepreciation?.filter_data?.month && <option value="month" >{assetDepreciation?.filter_data?.month}</option>}
                        {assetDepreciation?.filter_data?.week && <option value="week" >{assetDepreciation?.filter_data?.week}</option>}

                      </select>
                    </div>

                  </div>

                  <div style={{ height: "300px" }} className="chart-container" id="bar-workorder-by-status-container">
                    {assetDepreciation && chartData && <DeprictionChart chartData={chartData} />}
                  </div>

                </div>

              </div>
            </div>
          </div>
        </Card>
      }
      {(!viewAndEdit || !enableDeprication || assetDepreciation?.filter_data.length === 0) && <Card>
        <div className={classes.assetInfo}>
          <div className={classes.assetDeprication}>
            <h3>Asset Depreciation Details</h3>
            <p><input type="checkbox" checked={enableDeprication}
              id="depreciation_checkbox" onChange={handelEditDepricationChange} /> Track asset depreciation </p>
          </div>
          <div className={classes.changecontrolsGrid}>
            <div className={classes.controls}>
              <div className={classes.control}>
                <p>
                  Salvage Price <span className='red'>*</span>
                </p>
                <input
                  onChange={handelChangeDepricationForm}
                  value={assetDepricationForm?.salvage_price}
                  name='salvage_price'
                  disabled={!enableDeprication}
                  type="text" placeholder='RM 0.00'
                />
              </div>
              <div className={classes.control}>
                <p>
                  Service Life <span className='red'>*</span>
                </p>
                <div className={classes.wraperSelect}>

                  <input onChange={handelChangeDepricationForm}
                    value={assetDepricationForm?.service_life}
                    type="number"
                    disabled={!enableDeprication || asset?.from_ms}
                    name='service_life'
                  />
                  <select name='filter'
                    onChange={handelChangeDepricationForm}
                    value={assetDepricationForm?.filter}
                    disabled={!enableDeprication || asset?.from_ms}>
                    <option value="" disabled selected>Select Intervals</option>
                    {[
                      { label: "Year", value: "year" },
                      { label: "Month", value: "month" },
                      { label: "Week", value: "week" },
                    ].map(opt => (
                      <option key={opt.value} value={opt.value}>{opt.label}</option>
                    )
                    )}
                  </select>
                </div>
              </div>
              <div className={classes.controlReactSelect}>
                <SelectPersons
                  teams={teams}
                  assignedTo={assetAssignedTo}
                  setAssignedTo={setAssetAssignedTo}
                  stopped= {!enableDeprication}
                />   
                </div>
              <div className={classes.control}>
                <p>
                  Depreciation Start Date <span className='red'>*</span>
                </p>
                <input name='start_date'
                  onChange={handelChangeDepricationForm}
                  value={assetDepricationForm?.start_date}
                  type="date"
                  disabled={!enableDeprication || asset?.from_ms} />
              </div>
            </div>
          </div>
        </div>
      </Card>}
    </div>
  )
}

export default FetchAssetDepricationForm