/* eslint-disable import/prefer-default-export */
import React from 'react';

// import { faClipboardCheck } from '@fortawesome/fontawesome-free-solid';
import Dashboard from '../pages/dashboard/dashboard';
import AnalyticsReport from '../pages/analyticsReport/analyticsReport';
import ViewMaintenance from '../pages/viewMaintenance/viewMaintenance';
import WorkOrders from '../pages/workOrder/workOrders';
import Meter from '../pages/meter/meter';
import Locations from '../pages/locations/locations';
// import AssetMapping from '../pages/assetMapping/assetMapping';
import Inventories from '../pages/inventories/inventories';
import Teams from '../pages/teams/teams';
import Checklists from '../pages/checklists/checklists';
import EditCategory from '../pages/editCategory/editCategory';
import { AssetsPage } from '../pages';
import ChartBars from '../components/layout/components/icons/ChartBars';
import Planner from '../components/layout/components/icons/Planner';
import LocationsIcon from '../components/layout/components/icons/LocationsIcon';
import AssetsIcon from '../components/layout/components/icons/AssetsIcon';
import InventoriesIcon from '../components/layout/components/icons/InventoriesIcon';
import PreventiveManagement from '../components/layout/components/icons/PreventiveManagement';
import WorkOrdersIcon from '../components/layout/components/icons/WorkOrdersIcon';
import MeterIcon from '../components/layout/components/icons/MeterIcon';
import ChecklistIcon from '../components/layout/components/icons/ChecklistIcon';
import TeamsIcon from '../components/layout/components/icons/TeamsIcon';
import CustomizationIcon from '../components/layout/components/icons/CustomizationIcon';
import SettingsIcon from '../components/layout/components/icons/SettingsIcon';
import PropertyManagement from '../pages/PropertyManagement/PropertyManagement';

export const tabs = [
  {
    text: 'Analytic Report',
    id: 1,
    path: '/',
    icon: <ChartBars />,
    iconActive: <ChartBars fillColor="primary" />,
    // icon: faChartColumn,
    tabComponent: AnalyticsReport,
  },
  {
    text: 'Dashboard',
    id: 2,
    path: '/planner',
    style: 'mb-4',
    icon: <Planner />,
    iconActive: <Planner fillColor="primary" />,
    // icon: faTachometerAlt,
    tabComponent: Dashboard,
  },
  {
    text: 'Locations',
    id: 6,
    path: '/locations',
    icon: <LocationsIcon />,
    iconActive: <LocationsIcon fillColor="primary" />,
    // icon: faMapMarked,
    tabComponent: Locations,
  },
  {
    text: 'Property Management',
    id: 7,
    path: '/property',
    icon: <LocationsIcon />,
    iconActive: <LocationsIcon fillColor="primary" />,
    tabComponent: PropertyManagement,
  },
  {
    text: 'Assets',
    id: 8,
    path: '/assets',
    icon: <AssetsIcon />,
    iconActive: <AssetsIcon fillColor="primary" />,
    // icon: faTag,
    tabComponent: AssetsPage,
  },
  {
    text: 'Inventories/Parts\n',
    id: 9,
    path: '/inventories',
    style: 'mb-4',
    icon: <InventoriesIcon />,
    iconActive: <InventoriesIcon fillColor="primary" />,
    // icon: faBoxesPacking,
    tabComponent: Inventories,
  },
  {
    text: 'Preventive Maintenance',
    id: 3,
    path: '/maintenance',
    icon: <PreventiveManagement />,
    iconActive: <PreventiveManagement fillColor="primary" />,
    // icon: faScrewdriverWrench,
    tabComponent: ViewMaintenance,
  },
  {
    text: 'Work Orders',
    id: 4,
    path: '/workOrders',
    icon: <WorkOrdersIcon />,
    iconActive: <WorkOrdersIcon fillColor="primary" />,
    // icon: faList,
    tabComponent: WorkOrders,
  },
  {
    text: 'Meter',
    id: 5,
    path: '/meter',
    icon: <MeterIcon />,
    iconActive: <MeterIcon fillColor="primary" />,
    // icon: faGaugeHigh,
    /* `faGaugeHigh` is a reference to the FontAwesome icon `faGaugeHigh` from the
    `@fortawesome/free-solid-svg-icons` library. This icon is typically used to represent a
    high gauge or measurement value in a graphical user interface. */
    // style: 'mb-4',
    tabComponent: Meter,
  },
  {
    text: 'Checklists',
    id: 11,
    path: '/checklists',
    icon: <ChecklistIcon />,
    iconActive: <ChecklistIcon fillColor="primary" />,
    // icon: faClipboardCheck,
    style: 'mb-4',
    tabComponent: Checklists,
  },
  // {
  //   text: 'Asset Mapping',
  //   id: 8,
  //   path: '/assetMapping',
  //   icon: faEye,
  //   tabComponent: AssetMapping,
  // },

  {
    text: 'Teams',
    id: 10,
    path: '/teams',
    icon: <TeamsIcon />,
    iconActive: <TeamsIcon fillColor="primary" />,
    // icon: faUsers,
    tabComponent: Teams,
  },

  {
    text: 'Customization',
    id: 12,
    path: '/editCategory',
    icon: <CustomizationIcon />,
    iconActive: <CustomizationIcon fillColor="primary" />,
    // icon: faSliders,
    // style: 'mb-4',
    tabComponent: EditCategory,
  },
  {
    text: 'Settings',
    id: 13,
    path: '/settings',
    icon: <SettingsIcon />,
    iconActive: <SettingsIcon fillColor="primary" />,
    // icon: faGear,
    style: 'mb-4',
    tabComponent: EditCategory,
  },
];
