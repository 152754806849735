/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
// eslint-disable object-shorthand
/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
/* eslint-disable dot-notation */

import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  deleteTenantAttachment,
  reset,
  setCloseProp,
  setShowProp,
  setSingleLocation,
} from '../../../redux/features/PropertyManagement/PropertyManagementSlice';
import { createLocation, setLocationsLocations, updateLocation } from '../../../redux/features/location/locationSlice';

const PropertyManagementForm = () => {
  const { showProp, singleLocation, isPropUpdateSuccess, isAttachmentDeletedSuccess } = useSelector(state => state.property);
  const { locations } = useSelector(state => state.location);
  const [tenantId, setTenantId] = useState(null)
  const [attachId, setAttachId] = useState(null)
  const [locationinfo, setlocationInfo] = useState({
    site: '',
    code: '',
    street: '',
    district: '',
    city: '',
    state: '',
    postal_code: '',
  });
  const [management, setManagement] = useState({
    owner: null,
    category: null,
    quit_rent: null,
    assessment: null,
    insurance: null,
  });

  // useEffect(() => {
  //   setlocationInfo({
  //     site: singleLocation?.site,
  //     code: singleLocation?.location_code,
  //     street: singleLocation?.street,
  //     district: singleLocation?.district,
  //     city: singleLocation?.city,
  //     state: singleLocation?.state,
  //     postal_code: singleLocation?.postal_code,
  //   });
  //   setManagement({
  //     owner: singleLocation?.management?.owner,
  //     category: singleLocation?.management?.category,
  //   });
  // }, [singleLocation]);

  const dispatch = useDispatch();

  function resetInputField() {
    document.getElementById('site_name_new').value = '';
    document.getElementById('site_street_new').value = '';
    document.getElementById('site_district_new').value = '';
    document.getElementById('site_city_new').value = '';
    document.getElementById('site_state_new').value = '';
    document.getElementById('site_postcode_new').value = '';
    document.getElementById('tenant_name_new').value = '';
    document.getElementById('owner_name_new').value = '';
    document.getElementById('site_category').value = '';
    document.getElementById('rent_start_date').value = '';
    document.getElementById('rent_end_date').value = '';
    document.getElementById('description').value = '';
  }

  const handleCreateLocation = e => {
    e.preventDefault();
    dispatch(setShowProp());
    const info = locationinfo;
    info.management = management || null;
    dispatch(createLocation(info));
    resetInputField();
  };

  const section = {
    id: "",
    name: '',
    start_rental_date: '',
    end_rental_date: '',
    rental_price: '',
    deposit_for_rental: '',
    deposit_for_utility: '',
    insurance: '',
    quit_rent: '',
    assessment: '',
    others: '',
    description: '',
    attachments: [],
    files: []
  };
  const [tenants, setTenants] = useState([section]);

  useEffect(() => {
    setlocationInfo({
      site: singleLocation?.site,
      code: singleLocation?.location_code,
      street: singleLocation?.street,
      district: singleLocation?.district,
      city: singleLocation?.city,
      state: singleLocation?.state,
      postal_code: singleLocation?.postal_code,
    });
    setManagement({
      owner: singleLocation?.management?.owner,
      category: singleLocation?.management?.category,
      insurance: singleLocation?.management?.insurance,
      quit_rent: singleLocation?.management?.quit_rent,
      assessment: singleLocation?.management?.assessment,
    });
    if (singleLocation?.tenants?.length > 0) {
      setTenants(
        singleLocation?.tenants?.length > 0 && singleLocation?.tenants.map(item => {
          return { ...item, files: [] }
        })
      );
    }
  }, [singleLocation]);

  const addNewTenantSections = i => {
    setTenants([...tenants, section]);
  };
  const removeTenantSections = index => {
    const arr = [...tenants];
    if (tenants.length !== 0) {
      arr.splice(index, 1);
      setTenants(arr);
    }
  };
  const handleInput = (e, i) => {
    const { name, value } = e.target;
    setTenants(
      tenants.map((ten, index) => {
        if (index === i) {
          if (name !== "files") {
            return { ...ten, [name]: value }
          }
          return { ...ten, [name]: [...ten.files, { file: e.target.files[0]}] }
        }
        return ten
      })
    )
  };
  console.log(tenants);

  const deleteAttachment = (tenId, fileId) => {
    setAttachId(fileId)
    setTenantId(tenId)
    dispatch(deleteTenantAttachment({ tenId, fileId }))
  }

  const handleSaveProp = e => {
    e.preventDefault();
    const data = {
      id: singleLocation?.id,
      site: singleLocation?.site,
      street: singleLocation?.street,
      district: singleLocation?.district,
      state: singleLocation?.state,
      postal_code: singleLocation?.postal_code,
      city: singleLocation?.city,
      location_code: singleLocation?.location_code,
      management,
      tenants,
    };
    const formData = new FormData();
    formData.append('site', singleLocation?.site);
    formData.append('street', singleLocation?.street);
    formData.append('district', singleLocation?.district);
    formData.append('city', singleLocation?.city);
    formData.append('state', singleLocation?.state);
    formData.append('postal_code', singleLocation?.postal_code);
    formData.append('management.category', management.category || "");
    formData.append('management.owner', management.owner);
    formData.append('management.insurance', management.insurance);
    formData.append('management.quit_rent', management.quit_rent || "");
    formData.append('management.assessment', management.assessment);
    formData.append('location_code', singleLocation?.location_code);
    // tenants[i].attachments[index].file 
    if (tenants.length > 0) {
      const name = 'tenants';
      tenants?.forEach((item, i) => {
        formData.append(name + '[' + i + ']' + ['[id]'], item['id']);
        formData.append(name + '[' + i + ']' + ['[name]'], item['name']);
        formData.append(name + '[' + i + ']' + ['[start_rental_date]'], item['start_rental_date']);
        formData.append(name + '[' + i + ']' + ['[end_rental_date]'], item['end_rental_date']);
        formData.append(name + '[' + i + ']' + ['[rental_price]'], item['rental_price']);
        formData.append(name + '[' + i + ']' + ['[deposit_for_rental]'], item['deposit_for_rental']);
        formData.append(name + '[' + i + ']' + ['[deposit_for_utility]'], item['deposit_for_utility']);
        formData.append(name + '[' + i + ']' + ['[others]'], item['others']);
        formData.append(name + '[' + i + ']' + ['[description]'], item['description']);
        const combineAttachments = [...item.attachments , ...item.files]
        if (combineAttachments.length > 0) {
          combineAttachments.forEach((file, index) => {
            formData.append(`${name}[${i}][attachments][${index}][file]`, file.file);
          });
        }
      });
    }
    dispatch(updateLocation({ id: singleLocation?.id, data: formData }));

    if (!isPropUpdateSuccess) {
      dispatch(setCloseProp());
    }
  };

  useEffect(() => {
    if (isAttachmentDeletedSuccess) {
      const updatedTenant = tenants.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, attachments: tenant.attachments.filter(attachment => attachment.id !== attachId) }
        }
        return tenant
      }
      );
      setTenants(updatedTenant)
      toast.success('Attachment Deleted successfully', {
        toastId: 'DeletedAttachment',
      });
      dispatch(reset())
      setAttachId(null)
      setTenantId(null)
      dispatch(setLocationsLocations(locations?.map(loc => loc.id === singleLocation.id
        ? { ...singleLocation, tenants: updatedTenant } : loc)))
      // dispatch(setSingleLocation({...singleLocation , tenants: updatedTenant}))
    }

  }, [isAttachmentDeletedSuccess])





  return (
    <div
      className="card m-4 p-2 none"
      id="add-site"
      style={{ display: showProp ? 'block' : 'none' }}
    >
      <div className="row px-3 pt-3" />

      <form onSubmit={handleCreateLocation}>
        <div className="card-header">
          <div className="card-title">
            <h4 className="">
              <b>
                Site Information <i className="fa fa-lock text-black" />
              </b>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="col-12">
            <div className="row">
              <div className="location_error_msg">
                <span className="font-danger"> </span>
              </div>

              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_name_new">
                  Location Name{' '}
                  <label className="m-0 font-danger" htmlFor="site_name_new">
                    *
                  </label>
                </label>
                <input
                  name="site_name"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Site/Branch"
                  id="site_name_new"
                  value={locationinfo?.site}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_code_new">
                  Location Code{' '}
                </label>
                <input
                  name="site_name"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Site/Branch"
                  id="site_code_new"
                  value={locationinfo?.code ? locationinfo?.code : '--'}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_street_new">
                  Street
                </label>
                <input
                  name="site_street"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Street (Optional)"
                  id="site_street_new"
                  value={locationinfo?.street ? locationinfo?.street : '--'}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_district_new">
                  District
                </label>
                <input
                  name="site_district"
                  type="text"
                  className="form-control mb-2"
                  placeholder="District (Optional)"
                  id="site_district_new"
                  value={locationinfo?.district ? locationinfo?.district : '--'}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_city_new">
                  City
                </label>
                <input
                  name="site_city"
                  type="text"
                  className="form-control mb-2"
                  placeholder="City (Optional)"
                  id="site_city_new"
                  value={locationinfo?.city ? locationinfo?.city : '--'}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_state_new">
                  State
                </label>
                <input
                  name="site_state"
                  type="text"
                  className="form-control mb-2"
                  placeholder="State (Optional)"
                  id="site_state_new"
                  value={locationinfo?.state ? locationinfo?.state : '--'}
                />
              </div>

              <div className="col-4">
                <label className="font-weight-normal text-nowrap" htmlFor="site_postcode_new">
                  Postal Code
                </label>
                <input
                  name="site_postcode"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Postal Code (Optional)"
                  id="site_postcode_new"
                  value={locationinfo?.postal_code ? locationinfo?.postal_code : '--'}
                />
              </div>

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="location_name_error">
                  {' '}
                </span>
              </div>
            </div>
          </div>
        </div>

        <section style={{ marginTop: '-50px' }}>
          <div className="card-header">
            <div className="card-title">
              <h4 className="">
                <b>Property Information</b>
              </h4>
            </div>
          </div>
          <div style={{ marginBottom: '-50px' }} className="card-body">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <label className="font-weight-normal" htmlFor="owner_name_new">
                    Owner Name{' '}
                  </label>
                  <input
                    name="owner_name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Owner Name (Optional)"
                    value={management?.owner}
                    onChange={e => setManagement({ ...management, owner: e.target.value })}
                    id="owner_name_new"
                  />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12 ">
                  <label className="font-weight-normal">Property Category</label>
                  {/* value={propertyInformation?.category ? propertyInformation?.category : '--'} */}
                  <select
                    className=" mb-2 select-meter"
                    name="category"
                    id="site_category"
                    onChange={e => setManagement({ ...management, category: e.target.value })}
                    value={management?.category}
                  >
                    <option value="">Select a Category</option>
                    {['Rented', 'Rental'].map(val => (
                      <option value={val}>
                        {val}
                      </option>
                    )
                    )}
                  </select>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">Insurance</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="insurance"
                    id="insurance"
                    value={management?.insurance}
                    onChange={e => setManagement({ ...management, insurance: e.target.value })}
                  />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">Quit Rent</label>
                  <input
                    type="number"
                    min={0}
                    step={0.01}
                    className="form-control mb-2"
                    name="quit_rent"
                    id="deposit_for_utility"
                    value={management?.quit_rent}
                    onChange={e => setManagement({ ...management, quit_rent: e.target.value })}
                  />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">Assessment</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="assessment"
                    id="deposit_for_utility"
                    value={management?.assessment}
                    onChange={e => setManagement({ ...management, assessment: e.target.value })} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {tenants?.length > 0 &&
          tenants?.map((sec, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <section key={index} style={{ marginTop: '-50px' }}>
              <div className="card-header">
                <div className="">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className="">
                      <b>Tenant Information</b>
                    </h4>
                    {tenants?.length > 1 && (
                      <div className="flex-end">
                        <Button variant="primary" onClick={() => removeTenantSections(index)}>
                          x
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '-50px' }} className="card-body">
                <div className="col-12">
                  <div className="row">
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">Tenant Name</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="name"
                        id="tenant_full_name"
                        value={sec?.name}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">Start Rental Date</label>
                      <input
                        type="date"
                        className="form-control mb-2"
                        name="start_rental_date"
                        id="start_rental_date"
                        placeholder="Start Date"
                        value={sec?.start_rental_date}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">End Rental Date</label>
                      <input
                        type="date"
                        className="form-control mb-2"
                        name="end_rental_date"
                        id="rent_end_date"
                        placeholder="End Date"
                        value={sec?.end_rental_date}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">Rental Price</label>
                      <input
                        type="number"
                        min={0}
                        step={0.01}
                        className="form-control mb-2"
                        name="rental_price"
                        id="rent_price"
                        value={sec?.rental_price}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">Deposit for Rental</label>
                      <input
                        type="number"
                        min={0}
                        step={0.01}
                        className="form-control mb-2"
                        name="deposit_for_rental"
                        id="deposit_for_rental"
                        value={sec?.deposit_for_rental}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-4 col-md-4 col-sm-12">
                      <label className="font-weight-normal">Deposit for Utility</label>
                      <input
                        type="number"
                        min={0}
                        step={0.01}
                        className="form-control mb-2"
                        name="deposit_for_utility"
                        id="deposit_for_utility"
                        value={sec?.deposit_for_utility}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className=" col-lg-6 col-md-6 col-sm-12">
                      <label className="font-weight-normal">Others with Remarks</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="others"
                        id="deposit_for_utility"
                        value={sec?.others}
                        onChange={e => handleInput(e, index)}
                      />
                    </div>
                    <div className="col-lg-12 col-sm-12 ">
                      <label className="font-weight-normal">Description</label>
                      <textarea
                        name="description"
                        value={sec?.description}
                        onChange={e => handleInput(e, index)}
                        className="form-control mb-2"
                        placeholder="Description"
                        id="description"
                      />
                    </div>
                    <div className="col-lg-12 col-sm-12 ">
                      <label className="font-weight-normal">Attachment</label>
                      <input
                        type='file'
                        name="files"
                        multiple
                        onChange={e => handleInput(e, index)}
                        className="form-control mb-2"
                        placeholder="Attachment"
                        id="Attachments"
                      />
                    </div>

                    <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 pb-2">
                      <div className="card-body m-0 p-0 col-12">
                        <div className="row">
                          {sec?.attachments?.length > 0 &&
                            sec?.attachments?.map(file => (
                              <div className="container col-2 m-0 my-2 text-center" title={file.name}>
                                <button
                                  type="button"
                                  className="image p-2 photo"
                                  style={{ height: '100%', width: '100%' }}
                                >
                                  {file?.type === 'photo' ? (
                                    <a target="_blank" rel="noopener noreferrer"
                                      href="http://youtu.be/YdCwUhoKLm0"
                                    // href={file.photo}
                                    >
                                      <img
                                        id="myImg"
                                        alt="file"
                                        style={{ height: '100%', width: '100%' }}
                                        // src={file.photo}
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      style={{ fontSize: '12px' }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      // href="http://youtu.be/YdCwUhoKLm0"
                                      href={file.file}
                                      title={file.name}
                                    >
                                      {file.name.slice(0, 10)}...
                                    </a>
                                  )}
                                </button>
                                <button
                                  // disabled={isTechCanEdit}
                                  type="button"
                                  onClick={() => deleteAttachment(sec.id, file.id)}
                                  className="middle"
                                  style={{ opacity: 1 }}
                                >
                                  <span
                                    className="text font-danger"
                                    data-toggle="modal"
                                    data-target="#modal-attachment"
                                    title='Delete Attachment'
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} />
                                  </span>
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        <div style={{ marginLeft: '10px' }}>
          <Button variant="primary" onClick={addNewTenantSections}>
            <FontAwesomeIcon icon={faPlus} /> Add Tenant
          </Button>
        </div>
        <div className="card-body">
          <div className="float-right row">
            <div className="row float-right">
              <button
                title="Add Site"
                className="btn primary-btn ml-2"
                type="submit"
                onClick={e => handleSaveProp(e)}
              >
                Save
              </button>
              <button
                title="Add Site"
                onClick={() => {
                  dispatch(setCloseProp());
                }}
                className="btn secondary-btn ml-2"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default PropertyManagementForm;
